import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { FEATURE_TAGS, FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import IconButton from 'Common/Components/IconButton';
import { Tag } from '../../styled';
import { setCreationTagModal } from 'Screens/Tags/actions/Actions';

const TagButton = () => {
  const dispatch = useDispatch();
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const vulnsSelected = useSelector((state) => get(state, 'manage.vulnsSelected', []));
  const canCreateTags = useSelector((state) => selectAllowedByRole(state, FEATURE_TAGS, PERMISSION_TYPES.CREATE));
  const canPatchVulns = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.PATCH));
  const showButton = (!readonly && vulnsSelected.length > 0);

  if (!canCreateTags && !canPatchVulns) return null;
  return (
    showButton &&
    <IconButton icon={ <Tag /> } title="Tags" onClick={ () => dispatch(setCreationTagModal(true, 'menu')) } />
  );
};

export default TagButton;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createLink from 'Screens/NavMenu/Notifications/functions/createLink';
import createLinkFunc from 'Screens/NavMenu/Notifications/functions/createLinkFunc';
import parseIncomingNotification from 'Screens/NavMenu/Notifications/functions/parseIncomingNotification';
import { markNotificationsAsReadAsync } from 'store/Notifications/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

const NotificationMessageColumn = ({ original }) => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(markNotificationsAsReadAsync([original.id]));
  const selectedWorkspace = useSelector(selectCurrentWorkspace);

  if (typeof original !== 'object' || original === null) {
    return null;
  }
  return <div onClick={ handleClick }>
    {parseIncomingNotification(
      original.message,
      createLink(original.links_to?.type, selectedWorkspace, original.links_to?.id, dispatch),
      () => createLinkFunc(original, dispatch)
    )}
         </div>;
};

export default NotificationMessageColumn;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWorkspaceSelected
} from 'Screens/Workspaces/actions/Actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectWorkspaceUsers } from 'store/Session/selectors';
import { selectIsFetching, selectWorkspacesSelected } from 'store/Workspace/selectors';

const DeleteConfirmationModal = ({ onCloseCallback }) => {
  const dispatch = useDispatch();
  const selectedWs = useSelector(selectWorkspacesSelected);
  const users = useSelector(selectWorkspaceUsers);

  const wsHaveAdminUsersOnly = () => {
    // Workspaces can be deleted if none of them has a non-admin user assigned to them
    const nonAdminUsers = users.filter((user) => !user.roles.includes('admin'));
    // return workspaces
    return selectedWs.every((ws) => nonAdminUsers.every((user) => !user.workspaces.includes(ws.name)));
  };

  const deleteModalText = wsHaveAdminUsersOnly() ? '' : 'You will not be able to recover it. Assigned users will no longer be able to access it.';
  const isFetching = useSelector(selectIsFetching);

  return (
    <ModalConfirmation
      show
      handleSubmit={ () => dispatch(deleteWorkspaceSelected(onCloseCallback)) }
      handleClose={ onCloseCallback }
      entity="workspace"
      count={ selectedWs.length }
      message={ deleteModalText }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;

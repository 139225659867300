import React from 'react';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';
import { selectToolError } from 'store/Agents/selectors';
import { FieldWrapper, Label, Field, Error } from './styled';
import { FieldMessage } from 'Screens/Automation/Agents/components/Grid/components/UserAgents/components/RunUserAgentModal/components/ToolParams/components/Content/components/NonBooleanField/styled';
import { useIntl } from 'react-intl';

const fieldType = {
  boolean: 'checkbox',
  integer: 'number',
  string: 'text'
};

const NonBooleanField = ({ parameters, param, onChangeField, targetError }) => {
  const base = get(parameters, `${param}.base`, 'string');
  const required = get(parameters, `${param}.mandatory`, false);
  const paramType = get(fieldType, `${base}`, 'text');
  const paramError = useSelector((state) => selectToolError(state, param));
  const extractName = (str) => capitalize(str.replace('_', ' '));
  const type = get(parameters, `${param}.type`, false);
  const intl = useIntl();

  return (
    <FieldWrapper key={ param } required={ required }>
      <Label>{extractName(param)}</Label>
      { type === 'url'
        ? <>
          <Field onChange={ (e) => onChangeField(param, e.target.value, type) } failed={ paramError || targetError } title={ (paramError ? paramError.data[0] : param) || targetError } type={ paramType } placeholder="domain.com" />
          <Error>{ targetError }</Error>
          </>
        : <Field onChange={ (e) => onChangeField(param, e.target.value, type) } failed={ paramError } title={ paramError ? paramError.data[0] : param } type={ paramType } />
      }
      { type === 'list' && <FieldMessage>{intl.formatMessage({ id: 'agents.runAgent.listFieldMessage' })}</FieldMessage> }
    </FieldWrapper>
  );
};

export default NonBooleanField;

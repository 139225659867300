import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'Common/Components/Skeleton';
import { SwitchContainer } from '../styled';
import Switch from 'react-switch';
import { enableDisable } from 'Screens/Workspaces/actions/Actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import colors from 'Styles/colors';

const EnableDisableWs = ({ isFetching, ws, setShowConfirmation, setShowWarning }) => {
  const dispatch = useDispatch();
  const name = get(ws, 'name', '');
  const isWsActive = get(ws, 'active', false);
  const currrentWorkspace = useSelector(selectCurrentWorkspace);

  const changeWsStatus = (e, checked) => {
    e.stopPropagation();
    if (currrentWorkspace === name) {
      setShowWarning(true);
    } else {
      if (checked) {
        dispatch(enableDisable(name, checked));
      } else {
        setShowConfirmation({ show: true, ws: name });
      }
    }
  };

  if (isFetching) return <Skeleton />;
  return (
    <SwitchContainer checked={ isWsActive }>
      <Switch
        checked={ isWsActive }
        onChange={ (checked, e) => { changeWsStatus(e, checked); } }
        onColor={ colors.green4 }
        offColor={ colors.grey14 }
        onHandleColor={ colors.green3 }
        offHandleColor={ colors.grey15 }
        boxShadow="0 0 4px 0 rgba(83, 83, 83, 0.5)"
        activeBoxShadow=""
        height={ 14 }
        width={ 39 }
        handleDiameter={ 21 }
        uncheckedIcon={ false }
        checkedIcon={ false }
      />
    </SwitchContainer>
  );
};

export default EnableDisableWs;

import api from 'services/api';
import get from 'lodash/get';

import {
  CONTEXT_MENU_FILTER_KEYS, eq
} from 'store/Filters/constants';
import {
  selectQueryParam, selectIsGrouping, selectExpandedQueryParam, selectAdvancedFilterQueryParam, selectAdvancedExpandedQueryParam
} from 'store/Filters/selectors';
import {
  setPageNumber,
  setFilter,
  setOrderBy,
  setGroupBy,
  clearGroupBy,
  expandGroupBy,
  setFilterError,
  clearFilters,
  addFilter
} from 'store/Filters/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import {
  SEARCH_VULNS_START,
  GET_VULNS_SUCCESS_MANAGE,
  GET_GROUPED_DATA_SUCCESS,
  GET_GROUPED_DATA_START,
  SET_VULNS_FILTER_ERROR,
  ADD_MOCK_VULN
} from 'store/Manage/types';

import { onGetVulnsFail } from './actions';
import { selectTourFinished } from 'store/Preferences/Tour/selectors';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import { MOCK_VULN_LIST } from 'Common/Components/Tour/helpers/mockVuln';

export const setVulnsFilterError = () => async (dispatch) => dispatch({ type: SET_VULNS_FILTER_ERROR });

export function newGetVulns () {
  return async (dispatch, getState) => {
    dispatch({ type: SEARCH_VULNS_START });
    const state = getState();
    const workspaceSelected = selectCurrentWorkspace(state);
    let advancedFilterQueryParam = [];

    try {
      advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulns');
    } catch (e) {
      dispatch(setFilterError('vulns', 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setVulnsFilterError());
    }

    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('vulns', state);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    const groupBy = selectIsGrouping('vulns', state);

    try {
      const response = await api.manage.newGetVulns(workspaceSelected, queryParam);

      if (!response.status || response.status !== 'canceled') {
        const vulns = get(response, 'vulnerabilities', []);
        const result = vulns.map((vuln) => vuln.value);
        const vulnsCountArray = vulns.map((vuln) => vuln.value.count);
        const vulnsCountGroupBy = vulnsCountArray.reduce((prev, cur) => prev + cur, 0);
        // si es tour y no tiene vulns
        const isTourFinished = selectTourFinished(state);
        const showLegacyUI = selectShowLegacyUI(state);
        const vulnsCount = response.count || 0;

        if (vulnsCount === 0 && !isTourFinished && showLegacyUI) {
          return dispatch({
            type: ADD_MOCK_VULN,
            data: MOCK_VULN_LIST,
            vulnsCount: 1
          });
        }
        return dispatch({
          type: GET_VULNS_SUCCESS_MANAGE,
          data: result,
          vulnsCount: groupBy ? vulnsCountGroupBy : response.count
        });
      }
    } catch (e) {
      const errorMessage = get(e, 'message', 'Failed to obtain vulnerabilities.');
      if (errorMessage.includes('filter')) {
        dispatch(setFilterError('vulns', 'Invalid filters. Please try again. For further help check our documentation'));
        return dispatch(setVulnsFilterError());
      }
      return dispatch(onGetVulnsFail(errorMessage));
    }
  };
}

function getExpandedGroupByData (index) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_GROUPED_DATA_START });
    const state = getState();

    let advancedFilterQueryParam = [];
    try {
      advancedFilterQueryParam = selectAdvancedExpandedQueryParam(state, 'vulns');
    } catch (e) {
      dispatch(setFilterError('vulns', 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setVulnsFilterError());
    }
    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectExpandedQueryParam('vulns', state);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    const { faraday } = state;

    try {
      const response = await api.manage.newGetVulns(faraday.workspaceSelected, queryParam);

      if (!response.status || response.status !== 'canceled') {
        const result = response.vulnerabilities.map((vuln) => vuln.value);

        return dispatch({
          type: GET_GROUPED_DATA_SUCCESS,
          data: result,
          index
        });
      }
    } catch (e) {
      const errorMessage = get(e, 'message', 'Failed to obtain vulnerabilities.');
      if (errorMessage.includes('filter')) {
        dispatch(setFilterError('vulns', 'Invalid filters. Please try again. For further help check our documentation'));
        return dispatch(setVulnsFilterError());
      }
      return dispatch(onGetVulnsFail(errorMessage));
    }
  };
}

// CONTEXT MENU FILTERING FUNCTIONS -------------------------------------------------

export const contextMenuFilterByName = (selectedVuln) => {
  const filter = get(CONTEXT_MENU_FILTER_KEYS, 'name.filter', null);
  const nameValue = get(selectedVuln, 'name', '');
  filter.val = nameValue;
  return filter;
};
export const contextMenuFilterByAsset = (selectedVuln) => {
  const filter = get(CONTEXT_MENU_FILTER_KEYS, 'asset.filter', null);
  const targetValue = get(selectedVuln, 'target', '');
  filter.val = targetValue;
  return filter;
};
export const contextMenuFilterByDate = (selectedVuln) => {
  const filter = get(CONTEXT_MENU_FILTER_KEYS, 'date.filter', null);
  const date_parsed = new Date(get(selectedVuln, 'date', ''));
  const dateValue = `${date_parsed.getFullYear()}-${date_parsed.getMonth() + 1}-${date_parsed.getDate()}`;
  filter.val = dateValue;
  return filter;
};
export const contextMenuFilterByStatus = (selectedVuln) => {
  const filter = get(CONTEXT_MENU_FILTER_KEYS, 'status.filter', null);
  filter.val = selectedVuln.status;
  return filter;
};
export const contextMenuFilterByService = (selectedVuln) => {
  const filter = get(CONTEXT_MENU_FILTER_KEYS, 'service.filter', null);
  const serviceValue = get(selectedVuln, 'service.name', '');
  filter.val = {
    name: 'name',
    op: eq.name.value,
    val: serviceValue
  };
  return filter;
};
const contextMenuFilterByTags = (selectedVuln) => {
  const tagsArray = get(selectedVuln, 'tags', []);
  let tagFilters = [];
  tagsArray.forEach((tagFilter) => {
    const filter = {
      name: 'tags__name',
      op: eq.name.value,
      val: tagFilter
    };
    tagFilters = [...tagFilters, filter];
  });
  return tagFilters;
};

export const contextMenufilteringFunctions = {
  [CONTEXT_MENU_FILTER_KEYS.name.key.name]: contextMenuFilterByName,
  [CONTEXT_MENU_FILTER_KEYS.asset.key.name]: contextMenuFilterByAsset,
  [CONTEXT_MENU_FILTER_KEYS.date.key.name]: contextMenuFilterByDate,
  [CONTEXT_MENU_FILTER_KEYS.status.key.name]: contextMenuFilterByStatus,
  [CONTEXT_MENU_FILTER_KEYS.service.key.name]: contextMenuFilterByService,
  [CONTEXT_MENU_FILTER_KEYS.tags.key.name]: contextMenuFilterByTags
};

export function setContextMenuFilter (filterKey, selectedVuln) {
  return (dispatch) => {
    const newFilter = contextMenufilteringFunctions[filterKey](selectedVuln);
    if (filterKey === 'tags') {
      dispatch(clearFilters('vulns'));
      newFilter.forEach((tagFilter) => {
        dispatch(addFilter('vulns', tagFilter));
      });
    } else if (newFilter) dispatch(setFilter('vulns', newFilter));
    dispatch(newGetVulns());
  };
}
// ------------------------------------------------------------------------------

export function setPage (pageNumber) {
  return (dispatch) => {
    dispatch(setPageNumber('vulns', pageNumber));
    dispatch(newGetVulns());
  };
}

export function setOrderByVulns (sorting) {
  return (dispatch, getState) => {
    const state = getState();
    const isGrouping = selectIsGrouping('vulns', state);

    dispatch(setOrderBy('vulns', sorting));
    if (!isGrouping) dispatch(newGetVulns());
  };
}

export function setGroupByVulns (group_by) {
  return async (dispatch) => {
    const field = get(group_by, '[0].field', '');
    await dispatch(setGroupBy('vulns', group_by));
    if (field) await dispatch(setOrderBy('vulns', [{ id: field, desc: field === 'severity' }]));
    dispatch(newGetVulns());
  };
}

export function clearGroupByVulns () {
  return async (dispatch) => {
    await dispatch(clearGroupBy('vulns'));
  };
}

export function expandGroupByVulns (index, data) {
  return async (dispatch) => {
    await dispatch(expandGroupBy('vulns', data));
    dispatch(getExpandedGroupByData(index));
  };
}

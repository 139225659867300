/* eslint-disable no-empty */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RiFileEditLine as EditLine, RiFileDownloadLine as DownloadLine } from 'react-icons/ri';
import ManualCreationModal from 'Screens/KnowledgeBase/components/ManualCreationModal';
import useClickOutside from 'Hooks/useClickOutside';
import { setVisibilityCreateModal } from 'Screens/KnowledgeBase/actions/Actions';
import {
  DropdownText, DropdownItem, Dropdown, Wrapper, PlusIcon, DropdownWrapper
} from './styled';
import ImportFileModalContainer from '../ImportFileModalContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const CreateActions = ({ showModalCreate }) => {
  const [showImportFromFile, setShowImportFromFile] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.CREATE));
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));
  const dispatch = useDispatch();
  const handleShowImportFromFile = () => {
    setShowImportFromFile(true);
    handleDropdown();
  };
  const handleCloseImportFromFile = () => setShowImportFromFile(false);

  const handleShow = () => {
    dispatch(setVisibilityCreateModal(true));
    handleDropdown();
  };
  const handleClose = () => dispatch(setVisibilityCreateModal(false));

  const handleDropdown = () => setShowDropdown(!showDropdown);

  return (
      <Wrapper ref={ dropdownRef }>
        {
          canCreate
            ? <DropdownWrapper onClick={ handleDropdown }>
          <PlusIcon />
          <DropdownText><FormattedMessage id="template.create" /></DropdownText>
              </DropdownWrapper>
            : null
        }

        {showDropdown && (
          <Dropdown>
            <DropdownItem onClick={ handleShow } role="button" aria-label="Manual Creation" tabIndex="0" type="button">
              <EditLine size="1.3em" color="#5a6b7d" />
              Manual Creation
            </DropdownItem>
            <DropdownItem onClick={ handleShowImportFromFile } role="button" aria-label="Import from File" tabIndex="0" type="button">
              <DownloadLine size="1.3em" color="#5a6b7d" />
              Import from File
            </DropdownItem>
          </Dropdown>
        )}
        <ManualCreationModal show={ showModalCreate } handleClose={ handleClose } />
        <ImportFileModalContainer
          title={ <FormattedMessage id="template.import_csv" /> }
          subtitle="this is a subtitle"
          show={ showImportFromFile }
          handleClose={ handleCloseImportFromFile }
        />
      </Wrapper>
  );
};

CreateActions.propTypes = {
  showModalCreate: PropTypes.bool.isRequired
};

export default CreateActions;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './App.scss';
import './Styles/bootstrap.min.css';
import './Styles/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Error from 'Screens/Error';
import { validateSession } from 'Screens/Login/actions/Actions';
import { selectLocation } from 'store/Router/selectors';
import { selectIsLoggedIn, selectIsValidatingSession } from 'store/Sesion/selectors';
import { setUrlReturn } from 'Screens/Faraday/actions/Actions';
import NavMenu from 'Screens/NavMenu';
import SideBar from 'Screens/Sidebar';
import UserFeedback from 'Common/Components/UserFeedback';
import errorHandler from './Common/Handlers/ErrorHandler';
import FileUploadContext from './Common/Components/FileUploadContext';
import CommandValidator from './Common/Components/CommandValidator';
import LicenseValidator from './Common/Components/LicenseValidator';
import RedirectModal from './Common/Components/RedirectModal';
import { Content, Wrapper } from './styled';

import { initialize, trackPageView } from './tracking/GA';
import { selectShowProcessingQueue } from 'store/FileUploaderContext/selector';
import SpinnerComponent from 'Common/Components/SpinnerContainer';
import NavMenuContext from 'Screens/Contextualization/NavMenu';
import SideBarContext from 'Screens/Contextualization/Sidebar';
import TabsContext from 'Screens/Contextualization/Tabs';
import CommandValidatorContext from 'Common/Components/Contextualization/CommandValidatorContext';
import { selectShowLegacyUI } from 'store/Preferences/selectors';

const App = ({ logOut, children }) => {
  const gaKey = useSelector(() => (process.env.REACT_APP_GA_MEASUREMENT));
  const location = useSelector(selectLocation);
  const isValidatingSession = useSelector(selectIsValidatingSession);
  const showImportModal = useSelector(selectShowProcessingQueue);
  const loggedIn = useSelector((state) => selectIsLoggedIn(state));
  const showLegacyUI = useSelector(selectShowLegacyUI);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    dispatch(initialize(gaKey));
    if (location.hash.startsWith('#/status/ws/')) dispatch(setUrlReturn(location.hash.replace('#/status/ws/', 'manage/')));
    else dispatch(setUrlReturn(location.pathname));
    dispatch(validateSession());
    errorHandler(logOut);
    setMounted(true);
  }, [dispatch]);

  useEffect(() => {
    dispatch(trackPageView(`${location.pathname}${location.search}`));
  }, [dispatch, location]);

  if ((!mounted || isValidatingSession) && location.pathname !== '/') return null;
  if (isValidatingSession) return <SpinnerComponent height="100%" />;
  return (
    <>
    { showLegacyUI
      ? <Wrapper legacyUI={ showLegacyUI }>
          <NavMenu />
          <SideBar />
          <Content legacyUI={ showLegacyUI }>
            {
              children
            }
            <UserFeedback />
          </Content>
          <LicenseValidator />
          <RedirectModal />
          <Error />
          { (showImportModal && loggedIn) && <FileUploadContext /> }
          <CommandValidator />
        </Wrapper>
      : <Wrapper legacyUI={ showLegacyUI }>
            <NavMenuContext />
            <SideBarContext />
            <TabsContext />
            <Content legacyUI={ showLegacyUI }>
              { children }
              <UserFeedback />
            </Content>
            <LicenseValidator />
            <RedirectModal />
            <Error />
            { (showImportModal && loggedIn) && <FileUploadContext /> }
            <CommandValidatorContext />
        </Wrapper>
      }
    </>
  );
};

export default App;

import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as CloseIconImage } from 'Images/icon-close.svg';

export const Wrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  max-width: 600px;
  position: relative;
`;

Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  padding: 60px 30px 50px 30px;
  width: 225px;
  text-align: left;
`;

Content.displayName = 'Content';

export const TitleWrapper = styled.div`
  font-family: 'CentraNo2';
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #1c2243;
`;

TitleWrapper.displayName = 'TitleWrapper';

export const Description = styled.div`
  text-align: left;
  padding-top: 23px;
  padding-bottom: 23px;
  font-size: 14px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: ${colors.grey19};
`;
Description.displayName = 'Description';

export const LinkDescription = styled.a`
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: ${colors.blueCerulean};
  font-weight: 600;
`;
LinkDescription.displayName = 'LinkDescription';

export const FeatureWrapper = styled.div`
`;

FeatureWrapper.displayName = 'FeatureWrapper';

export const FeatureImage = styled.img`
  width: 305px;
`;

FeatureImage.displayName = 'FeatureImage';

export const CloseIcon = styled(CloseIconImage)`
  width: 22px;
  height: 20px;
  position: absolute;
  right: 26px;
  top: 26px;

  * > path {
    color: ${colors.greyBlue};
  }
`;

CloseIcon.displayName = 'CloseIcon';

export const TitleBold = styled(TitleWrapper)`
  display: inline;
  font-weight: 500;
`;

TitleBold.displayName = 'TitleBold';

// Colors

export default {
  black: '#000',
  black1: '#3b3b3b',
  black2: '#2c3e50',
  black3: '#686a76',
  white: '#fff',
  white1: '#fafbfc',
  dark1: '#273e55',
  dark2: '#1c2243',
  dark3: '#292929',
  dark4: '#426485',
  dark5: '#53686f',
  dark6: '#707070',
  purple1: '#9b72ea',
  purple2: '#a581eb',
  purple3: '#c846ce',
  purple5: '#b95bba',
  purple6: '#a14ca4',
  warmPurple: '#a4247a',
  light1: '#fafcfd',
  light2: '#ededed',
  light3: '#e9e9e9',
  light4: '#eff1f4',
  lightGrey: '#d9e4ef',
  paleGrey: '#fafbfc',
  grey1: '#c2cbd5',
  grey2: '#82b8e5',
  grey3: '#aea3a3',
  grey4: '#989898',
  grey5: '#8da6be',
  grey6: '#7c97b2',
  grey7: '#a6bcd1',
  grey8: '#afb3b8',
  grey9: '#adb6c1',
  grey10: '#b7b7b7',
  grey11: '#7a7e8f',
  grey12: '#bec8d2',
  grey13: '#dcdcdc',
  grey14: '#ececec',
  grey15: '#bdbdbd',
  grey16: '#cfcfcf',
  grey17: '#afb1bc',
  grey18: '#c6c5c4',
  grey19: '#63758d',
  grey20: '#c5c5c5',
  grey21: '#9699ae',
  grey22: '#7389A5',
  grey23: '#e2e2e2',
  grey24: '#dbe2e7',
  grey25: '#e3e7ec',
  grey26: '#f7fbff',
  grey27: '#d6d6d6',
  grey28: '#B0C8DF',
  grey29: '#8b99b2',
  grey30: '#afafaf',
  grey31: '#a8a8a8',
  grey32: '#dbdbdb',
  blue1: '#33495e',
  blue2: '#586f85',
  blue3: '#e7e8e9',
  blue4: '#ebeef2',
  blue5: '#8da6be',
  blue6: '#1c2243',
  blue7: '#e2f0fb',
  blue8: '#edf2f7',
  blue9: '#419bf9',
  blue10: '#0067ff',
  blue11: '#1c4566',
  blue12: '#ebeff3',
  blue13: '#5395ff',
  blue14: '#2996f0',
  darkBlueGrey: '#1c2243',
  blueCerulean: '#0082ed',
  softBlue: '#53a9ff',
  iceBlue: '#edf2f7',
  greyBlue: '#63758d',
  lightBlue: '#bad8f1',
  red1: '#dc4a4a',
  red2: '#e26464',
  red3: '#ea3158',
  red4: '#d43030',
  red5: '#dd494a',
  redPink: '#ea3158',
  orange1: '#f1ae62',
  orange2: '#f59220',
  orange3: '#f6a34c',
  orange4: '#f1ad62',
  green1: '#88cc3f',
  green2: '#a1ce31',
  green3: '#74ab39',
  green4: '#d5f3b5',
  green5: '#94cc88',
  green6: '#60ad54',
  green7: '#89cc3f',
  apple: '#88cc3f',
  blueGrey: '#90a9c0',
  blueGrey2: '#264d6d',
  veryLightPink: '#d6d6d6',
  primaryColor: '#ffffff',
  tangerine: '#f59220',
  yellow1: '#fffced',
  paleGold: '#ffd76b',
  lightYellow: '#ffe7a6',
  lightOrange: '#fac68a'
};

import Importance from 'Common/Components/Importance';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateHost } from 'store/Host/actions';
import { Wrapper } from './styled';

const ImportanceColumn = ({ host }) => {
  const dispatch = useDispatch();
  const [importance, setImportance] = useState();

  useEffect(() => {
    if (host && host.importance) setImportance(host.importance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host]);

  const callback = (importance) => {
    setImportance(importance);
    dispatch(updateHost(host._id, { importance }));
  };

  return (
    <Wrapper>
      <Importance importance={ importance } callback={ callback } />
    </Wrapper>
  );
};

export default ImportanceColumn;

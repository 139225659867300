import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/CustomActionBar/styled';
import { setPage } from 'Screens/KnowledgeBase/actions/Actions';
import { selectKnowledgeBaseProps } from 'store/KnowledgeBase/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import CreateActions from '../CreateActions';
import EditButton from './components/EditButton';
import DeleteButton from './components/DeleteButton';
import Pagination from 'Common/Components/Pagination';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import RefreshButton from 'Screens/KnowledgeBase/components/RefreshButton';

const ActionBar = () => {
  const dispatch = useDispatch();
  const knowledgeBaseProps = useSelector(selectKnowledgeBaseProps);
  const {
    templatesSelected,
    readonly,
    showModalCreate,
    totalVulnTemplate,
    rowsPerPage,
    page,
    showDeleteConfirmation
  } = knowledgeBaseProps;
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.CREATE));
  const showKbActionButtons = (!readonly && (templatesSelected.length > 0) && canCreate);
  const hasTemplates = totalVulnTemplate > 0;
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));

  return (
    <Wrapper>
      { showDeleteConfirmation ? <DeleteConfirmationModal /> : null }
      <LeftGroup>
        {!readonly && <CreateActions showModalCreate={ showModalCreate } /> }
        { showKbActionButtons &&
          <>
            <Separator />
            <Group>
              <EditButton />
              <DeleteButton />
            </Group>
            <Separator />
          </>
        }
      </LeftGroup>
      { hasTemplates &&
        <RightGroup>
          <Pagination offset={ page } limit={ rowsPerPage } total={ totalVulnTemplate } onPrev={ onPrev } onNext={ onNext } />
          <Group>
            <RefreshButton />
          </Group>
        </RightGroup>
      }
    </Wrapper>
  );
};

export default ActionBar;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import handleLeftClick from 'Common/Components/CustomReactTable/leftClickHelper';
import {
  ContextMenuTrigger
} from 'react-contextmenu';
import ContextMenuWorkspaceMassiveUpdate from 'Screens/Workspaces/components/ContextMenuWorkspaceMassiveUpdate';
import {
  changeReadOnly,
  selectWorkspace,
  setOrderByWorkspaces
} from 'Screens/Workspaces/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetching, selectLastIndexForRange, selectLastSelected, selectLastWorkspaceSelected, selectWorkspacesOrdered, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { PERMISSION_TYPES, FEATURE_WORKSPACES } from 'Hooks/usePermission/permissions';
import { TableWrapper } from './styled';
import getColumns from './Columns';
import { FormattedMessage } from 'react-intl';
import { selectCurrentWorkspace, selectIsAdmin } from 'store/Faraday/selectors';
import EmptyState from '../EmptyState';

const Table = ({ setShowConfirmation, setShowWarning }) => {
  const dispatch = useDispatch();
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const lastWorkspaceSelected = useSelector(selectLastWorkspaceSelected);
  const lastIndexForRange = useSelector(selectLastIndexForRange);
  const lastSelected = useSelector(selectLastSelected);
  const workspaceList = useSelector(selectWorkspacesOrdered);
  const isFetching = useSelector(selectIsFetching);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.UPDATE));
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const isAdmin = useSelector(selectIsAdmin);

  const getSorting = (sorted) => {
    const vulnsSortIndex = sorted.findIndex((s) => s.id === 'vulnerabilities');

    if (vulnsSortIndex >= 0) {
      const desc = sorted[vulnsSortIndex].desc;
      const sortTotalCount = { id: 'vulnerability_total_count', desc };

      const newSorting = [...sorted];
      newSorting.splice(vulnsSortIndex, 1, sortTotalCount);
      return newSorting;
    }
    return sorted;
  };

  const changeReadOnlyStatus = (ws, readonly) => dispatch(changeReadOnly(ws, readonly));

  const handleClick = (e, rowInfo) => {
    const selected = workspacesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    const leftClickResponse = handleLeftClick(e, rowInfo, '', selected, workspaceList, workspacesSelected, lastWorkspaceSelected, lastIndexForRange, lastSelected);
    if (leftClickResponse) dispatch(selectWorkspace(leftClickResponse));
  };

  const getTrProps = (state, rowInfo) => {
    const selected = workspacesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    return {
      onClick: (e) => {
        handleClick(e, rowInfo);
        return true;
      },
      onContextMenu: () => {
        if (!selected) dispatch(selectWorkspace({ newRowsSelected: [rowInfo.original] }));
        return false;
      },
      style: {
        background: selected ? '#edf2f7' : 'transparent'
      }
    };
  };

  const fetchData = (state) => {
    const { sorted } = state;
    if (!isFetching && currentWorkspace) {
      const sorting = getSorting(sorted);
      dispatch(setOrderByWorkspaces(sorting));
    }
  };

  const mockData = [...Array(13).keys()];

  if (workspaceList.length === 0 && !isAdmin) return <EmptyState />;
  return (
    <TableWrapper>
      <ContextMenuTrigger id="massive_update_workspace_table" holdToDisplay={ 1000000 }>
        <CustomReactTable
          data={ isFetching ? mockData : workspaceList }
          columns={ getColumns(changeReadOnlyStatus, handleClick, canUpdate, isFetching, setShowConfirmation, setShowWarning) }
          minRows={ 0 }
          style={ { height: 'calc(100vh - 158px)', boxShadow: '-8px 14px 20px 0px rgba(0, 0, 0, 0.03)' } }
          onFetchData={ fetchData }
          manual
          getTrProps={ getTrProps }
          showPagination={ false }
          noDataText={ <FormattedMessage id="app.nodata" /> }
        />
      </ContextMenuTrigger>
      { canUpdate
        ? <ContextMenuWorkspaceMassiveUpdate trigger="massive_update_workspace_table" onShow={ () => { } } />
        : null }
    </TableWrapper>
  );
};

export default Table;

import React, { useState, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GET_SUMMARY_SUCCEED_DASHBOARD, showModalImportDashboard } from 'Screens/Dashboard/actions/Actions';
import { FormattedMessage } from 'react-intl';
import ModalCreation from 'Screens/Workspaces/components/ModalCreation';
import { PropTypes } from 'prop-types';
import { saveWorkspaceHistory } from 'Screens/Faraday/actions/Actions';
import selectModal from 'store/modals/selectors';
import { closeModal, openModal } from 'store/modals/actions';
import { selectCurrentWorkspace, selectFaradayLoading } from 'store/Faraday/selectors';
import useClickOutside from 'Hooks/useClickOutside';
import { selectAllowGetUsers } from 'store/Session/selectors';
import replace from 'lodash/replace';
import get from 'lodash/get';
import { MODAL_WORKSPACE_CREATE_EDIT } from 'store/modals/modals';
import { redirect } from 'store/Router/actions';
import { selectLocation } from 'store/Router/selectors';
import { getWorkspacesForSelector, setWsStatsError } from 'Screens/Workspaces/actions/Actions';
import { clearFilters, setPageNumber, resetFilters } from 'store/Filters/actions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { newGetVulns } from 'store/Manage/filterActions';
import {
  Wrapper, Toggle, Menu, Item, WorkspaceSelectedName,
  ArrowDown, WorkspaceOptionName, ActionsContainer,
  ViewAll, CreateNew, WorkspaceVulns, NoItems
} from './styled';
import { resetVulnerabilityDetail } from 'store/Manage/actions';

import { selectWorkspacesForSelector } from 'store/Workspace/selectors';
import api from 'services/api';
import SpinnerComponent from 'Common/Components/SpinnerContainer';
import formatNumber from 'Common/Functions/FormatNumber';

const WorkspaceSelector = ({
  saveWorkspaceHistoryProp, workspaces, workspaceSelected
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const noWorkspaces = workspaces.length === 0;
  const showNoItem = noWorkspaces || (workspaces.length === 1 && workspaces[0].name === workspaceSelected);
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));
  const location = useSelector(selectLocation);
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.CREATE));
  const isManage = get(location, 'pathname', '').includes('/manage');
  const isAssets = get(location, 'pathname', '').includes('/host');
  const isDashboard = get(location, 'pathname', '').includes(`/feed/${workspaceSelected}`);
  const isFetching = useSelector(selectFaradayLoading);
  const totalVulnsForDashboard = useSelector((state) => get(state, 'dashboard.tools[3].data.stats.total_vulns', 0));
  const vulnText = () => {
    if (totalVulnsForDashboard === 0) return '';
    else if (totalVulnsForDashboard > 1) return `(${formatNumber(totalVulnsForDashboard)} vulnerabilities)`;
    else return `(${formatNumber(totalVulnsForDashboard)} vulnerability)`;
  };

  const redirectTo = (ws) => {
    let url = '';
    if (location.pathname === '/404') {
      url = `/feed/${ws.name}`;
    } else {
      url = replace(location.pathname, workspaceSelected, ws.name);
    }
    dispatch(redirect(`${url}`));
  };

  const selectWorkspace = async (ws) => {
    saveWorkspaceHistoryProp(ws.name);
    if (!isDashboard) {
      try {
        const response = await api.workspace.getWsStats(ws.name);
        dispatch({ type: GET_SUMMARY_SUCCEED_DASHBOARD, data: response });
      } catch (error) {
        dispatch(setWsStatsError(error.message));
      }
    }
    if (ws.stats.total_vulns === 0) dispatch(showModalImportDashboard(true));
    redirectTo(ws);
    if (isManage) {
      dispatch(setPageNumber('vulns', 1));
      dispatch(newGetVulns());
    }
    if (isAssets) dispatch(setPageNumber('assets', 1));
  };

  const getItems = () => {
    if (isFetching) return <SpinnerComponent />;
    if (showNoItem) {
      return (
        <NoItems><FormattedMessage id="workspaces.contextMenu.no_other" /></NoItems>
      );
    }

    const onClick = (ws) => {
      selectWorkspace(ws);
      setShowDropdown(false);
      dispatch(clearFilters('vulns'));
      dispatch(clearFilters('assets'));
      dispatch(clearFilters('vulnsAssets'));
      dispatch(resetFilters('dashboard'));
      dispatch(resetVulnerabilityDetail());
    };
    return workspaces.map((ws, index) => {
      if (ws.name !== workspaceSelected) {
        return (
          <Item id={ `ws-selector-${ws.name}` } onClick={ () => onClick(ws) } key={ ws.name } lastelement={ index + 1 === workspaces.length }>
            <WorkspaceOptionName>{ws.name}</WorkspaceOptionName>
            <WorkspaceVulns>{formatNumber(ws.stats.total_vulns)}</WorkspaceVulns>
          </Item>
        );
      } return null;
    });
  };

  const show = useSelector((state) => selectModal(state, MODAL_WORKSPACE_CREATE_EDIT));
  const handleClose = () => dispatch(closeModal(MODAL_WORKSPACE_CREATE_EDIT));
  const handleOpen = () => dispatch(openModal(MODAL_WORKSPACE_CREATE_EDIT));
  const handleToggle = () => {
    setShowDropdown(!showDropdown);
    if (!showDropdown) {
      dispatch(getWorkspacesForSelector());
    }
  };

  return (
    <Wrapper ref={ dropdownRef }>
      <Toggle onClick={ handleToggle }>
        <WorkspaceSelectedName id={ `ws-selector-selected-${workspaceSelected}` } title={ workspaceSelected || 'No Workspace Selected' }>
          {`${workspaceSelected} ${vulnText()}` || 'No Workspace Selected'}
        </WorkspaceSelectedName>
        <ArrowDown />
      </Toggle>
      { workspaces.length >= 0 && showDropdown && (
        <Menu>
          {getItems()}
          <ActionsContainer>
            {!noWorkspaces && <ViewAll onClick={ () => { setShowDropdown(false); dispatch(redirect('/workspaces')); } }><FormattedMessage id="workspaces.contextMenu.viewAll" /></ViewAll>}
            { canCreate && <CreateNew onClick={ () => { setShowDropdown(false); handleOpen(); } }><FormattedMessage id="workspaces.contextMenu.createNew" /></CreateNew> }
          </ActionsContainer>
        </Menu>
      ) }
      {show && <ModalCreation show={ show } handleClose={ handleClose } /> }
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  workspaces: selectWorkspacesForSelector(state),
  workspaceSelected: selectCurrentWorkspace(state),
  allowGetUsers: selectAllowGetUsers(state)
});

const mapDispatchToProps = (dispatch) => ({
  saveWorkspaceHistoryProp: (ws) => {
    dispatch(saveWorkspaceHistory(ws));
  }
});

WorkspaceSelector.propTypes = {
  workspaces: PropTypes.instanceOf(Object).isRequired,
  workspaceSelected: PropTypes.string.isRequired,
  saveWorkspaceHistoryProp: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspaceSelector));

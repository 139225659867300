import Importance from 'Common/Components/Importance';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateWS } from 'Screens/Workspaces/actions/Actions';
import { Wrapper } from './styled';

const ImportanceColumn = ({ ws }) => {
  const dispatch = useDispatch();
  const [importance, setImportance] = useState();

  useEffect(() => {
    if (ws && ws.importance) setImportance(ws.importance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callback = (importance) => {
    setImportance(importance);
    dispatch(updateWS(ws.name, { importance }, false));
  };

  return (
    <Wrapper>
      <Importance importance={ importance } callback={ callback } />
    </Wrapper>
  );
};

export default ImportanceColumn;

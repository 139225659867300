import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from './styled';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { ACTIONS } from 'tracking/GA/constants';
import { resetWarning } from 'store/Settings/settingsActions';
import selectModal from 'store/modals/selectors';
import {
  MODAL_UPGRADE_LICENSE, MODAL_JIRA_TEMPLATE, MODAL_SEND_TO_JIRA,
  MODAL_VULNS_NOT_CONFIRMED, MODAL_SEND_TO_SERVICENOW, MODAL_SEND_TO_GITLAB,
  MODAL_SEND_TO_WHD
} from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import sendToToolLicenseImage from 'Images/sendtotool-license.png';
import useModal from 'Hooks/useModal';
import ReduxModal from 'Common/Components/ReduxModal';
import Warning from 'Common/Components/Warning';
import JiraTemplateModal from 'Common/Components/JiraTemplateModal';
import Jira from 'Common/Components/SendToTool/components/Jira';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import SetProject from 'Common/Components/SendToTool/components/Jira/SetProject';
import ServiceNow from 'Common/Components/SendToTool/components/ServiceNow';
import GitLab from 'Common/Components/SendToTool/components/GitLab';
import SolarWinds from 'Common/Components/SendToTool/components/SolarWinds';
import { Blue, ConfirmedIcon } from 'Common/Components/SendToTool/styled';

const IntegrationsModals = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [ModalJira, showModalJira, toggleModalJira] = useModal(Jira);
  const showJiraTemplateModal = useSelector((state) => selectModal(state, MODAL_JIRA_TEMPLATE));
  const showSendToJiraModal = useSelector((state) => selectModal(state, MODAL_SEND_TO_JIRA));
  const showModal = useSelector((state) => selectModal(state, MODAL_UPGRADE_LICENSE));
  const showModalVulnsNotConfirmed = useSelector((state) => selectModal(state, MODAL_VULNS_NOT_CONFIRMED));
  const showModalServiceNow = useSelector((state) => selectModal(state, MODAL_SEND_TO_SERVICENOW));
  const showModalGitLab = useSelector((state) => selectModal(state, MODAL_SEND_TO_GITLAB));
  const showModalSolarWinds = useSelector((state) => selectModal(state, MODAL_SEND_TO_WHD));
  const error = useSelector((state) => get(state, 'settings.sendVulnToTool.payload.error', false));
  const message = useSelector((state) => get(state, 'settings.sendVulnToTool.payload.message', ''));

  return (
    <>
      { showModalJira && <ModalJira onClose={ toggleModalJira } /> }
      { showModalServiceNow &&
        <ReduxModal id={ MODAL_SEND_TO_SERVICENOW }>
          <ModalWrapper>
            <ServiceNow onClose={ () => dispatch(closeModal(MODAL_SEND_TO_SERVICENOW)) } />
          </ModalWrapper>
        </ReduxModal>
      }
      { showModalGitLab &&
        <ReduxModal id={ MODAL_SEND_TO_GITLAB }>
          <ModalWrapper>
            <GitLab onClose={ () => dispatch(closeModal(MODAL_SEND_TO_GITLAB)) } />
          </ModalWrapper>
        </ReduxModal>
      }
      { showModalSolarWinds &&
        <ReduxModal id={ MODAL_SEND_TO_WHD }>
          <ModalWrapper>
            <SolarWinds onClose={ () => dispatch(closeModal(MODAL_SEND_TO_WHD)) } />
          </ModalWrapper>
        </ReduxModal>
      }

      { showModal && (
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ sendToToolLicenseImage } descriptionIntlId="upgradeLicense.sendToTool.description" trackingShow={ ACTIONS.showSendToTool } trackingClick={ ACTIONS.clickSendToTool } />
        </ReduxModal>
      ) }
      { showModalVulnsNotConfirmed && (
        <ReduxModal id={ MODAL_VULNS_NOT_CONFIRMED }>
          <ModalWrapper>
            <Warning title={ intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.title' }) } onClose={ () => dispatch(closeModal(MODAL_VULNS_NOT_CONFIRMED)) }>
            { intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.onlyConfirmedVulns' }) }
              <ConfirmedIcon />
              <Blue>{ intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.confirmed' }) }</Blue>
              { intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.tryAgain' }) }
            </Warning>
          </ModalWrapper>
        </ReduxModal>
      ) }
      {showJiraTemplateModal && <JiraTemplateModal />}
      {showSendToJiraModal && <SetProject />}
      { error && message && <ModalWrapper><Warning title={ intl.formatMessage({ id: 'sendToTool.warnings.oops.title' }) } onClose={ () => dispatch(resetWarning()) }>{message}</Warning></ModalWrapper>}
    </>
  );
};

export default IntegrationsModals;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditButton from 'Common/Components/EditButton';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { selectHostsSelected } from 'store/Host/selectors';
import { selectShowHostDetail } from 'store/HostDetail/selectors';
import { showEditModal } from 'store/HostEditCreate/actions';

const EditHostButton = () => {
  const dispatch = useDispatch();
  const hostsSelected = useSelector(selectHostsSelected);
  const isHostDetailVisible = useSelector(selectShowHostDetail);

  return (
    <WithPermission permission={ FEATURE_HOSTS } type={ PERMISSION_TYPES.UPDATE }>
     <EditButton editFunction={ () => dispatch(showEditModal()) } selectedEntities={ hostsSelected } display={ hostsSelected.length === 1 && !isHostDetailVisible } />
    </WithPermission>
  );
};

export default EditHostButton;

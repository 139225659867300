import React from 'react';
import DateFormatter from 'Common/Components/DateFormatter';
import {
  Text, TimeStamp, ListItem, Details
} from './styled';
import { Flex } from 'Screens/Settings/components/TicketingTools/components/Jira/components/ProjectsDropdown/styled';
import parseIncomingNotification from '../functions/parseIncomingNotification';
import createLink from '../functions/createLink';
import { useDispatch, useSelector } from 'react-redux';
import { markNotificationsAsReadAsync } from 'store/Notifications/actions';
import createLinkFunc from '../functions/createLinkFunc';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

const NotificationItem = ({
  data, creationTime
}) => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(markNotificationsAsReadAsync([data.id]));
  const selectedWorkspace = useSelector(selectCurrentWorkspace);

  return (data.message &&
    (
    <ListItem onClick={ handleClick } read={ data.read } >
      <Flex>
        <div>
      <Text>
        {parseIncomingNotification(
          data.message,
          createLink(data.links_to?.type, selectedWorkspace, data.links_to?.id),
          () => createLinkFunc(data, dispatch)
        )}
      </Text>
      <Details>
        <TimeStamp>
          <DateFormatter date={ creationTime } />
        </TimeStamp>
      </Details>
        </div>
      </Flex>
    </ListItem>
    )
  );
};

export default NotificationItem;

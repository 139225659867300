import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import DeleteButton from 'Common/Components/DeleteButton';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { showHostModalDelete } from 'store/Host/actions';
import { selectHostsSelected } from 'store/Host/selectors';
import { selectShowHostDetail } from 'store/HostDetail/selectors';

const DeleteHostButton = () => {
  const dispatch = useDispatch();
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const hostsSelected = useSelector(selectHostsSelected);
  const isHostDetailVisible = useSelector(selectShowHostDetail);

  return (
    <WithPermission permission={ FEATURE_HOSTS } type={ PERMISSION_TYPES.DELETE }>
      <DeleteButton readonly={ readonly } entity="host" selectedEntities={ hostsSelected } display={ hostsSelected.length > 0 && !isHostDetailVisible } openConfirmationModal={ () => dispatch(showHostModalDelete()) } />
    </WithPermission>
  );
};

export default DeleteHostButton;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { FEATURE_TAGS, FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import Wrapper from './styled';
import IconButton from 'Common/Components/IconButton';
import { Tag } from 'Screens/Manage/components/CustomActionBar/styled';
import { setCreationTagModal } from 'Screens/Tags/actions/Actions';
import { selectHostsSelected } from 'store/Host/selectors';

const TagButton = () => {
  const dispatch = useDispatch();
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const hostsSelected = useSelector(selectHostsSelected);
  const canCreateTags = useSelector((state) => selectAllowedByRole(state, FEATURE_TAGS, PERMISSION_TYPES.CREATE));
  const canPatchHosts = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.PATCH));
  const showButton = (!readonly && hostsSelected.length > 0);

  if (!canCreateTags && !canPatchHosts) return null;
  return (
    showButton &&
    <Wrapper>
      <IconButton icon={ <Tag /> } title="Tags" onClick={ () => dispatch(setCreationTagModal(true, 'hosts')) } />
    </Wrapper>
  );
};

export default TagButton;

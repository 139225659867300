import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateCvss } from 'store/Manage/actions';
import Wrapper from './styled';
import CVSS from './cvss';
import { updateCvssGeneral } from 'store/ManageGeneral/actions';
import { updateCvssFromAsset } from 'store/Contextualization/AssetDetail/actions';

const Calculator = ({ cvssData, entity }) => {
  const dispatch = useDispatch();
  const cvssRef = useRef();

  useEffect(() => {
    const cvss = new CVSS('cvssboard', {
      onchange: function (e) {
        const newCvss = cvss.get();
        if (entity === 'vulns') {
          dispatch(updateCvss(newCvss));
        } else if (entity === 'vulnsGeneral') {
          dispatch(updateCvssGeneral(newCvss));
        } else {
          dispatch(updateCvssFromAsset(newCvss));
        }
      }
    });

    if (cvssData.vector_string) {
      cvss.set(`CVSS:3.1/${cvssData.vector_string}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper ref={ cvssRef }>
       <div id='cvssboard' />
    </Wrapper>
  );
};

export default Calculator;

import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { Edit } from 'Screens/Manage/components/CustomActionBar/styled';
import { showVulnTemplateModalEdit } from 'Screens/KnowledgeBase/actions/Actions';

const EditButton = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showVulnTemplateModalEdit());

  return (
    <WithPermission permission={ FEATURE_VULN_TEMPLATE } type={ PERMISSION_TYPES.UPDATE }>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } />
    </WithPermission>
  );
};

export default EditButton;

import React from 'react';
import { useSelector } from 'react-redux';
import Graphs from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs';
import Header from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Header';
import Table from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Table';
import {
  graphTemplates, selectData, selectType, selectWorkspaces
} from 'store/Analytics/selectors';
import { isEmpty } from 'lodash';
import Empty from 'Screens/Analytics/components/CreateEdit/components/EditGraph/components/Empty';

import { Wrapper, WrapperPreview } from './styled';
import { MONTHLYEVOLUTIONBYSEVERITY, MONTHLYEVOLUTIONBYSTATUS } from 'store/Analytics/graphs';
import { parseData } from 'Screens/Analytics/components/CreateEdit/components/Preview/components/Graphs/components/utils';
import { selectShowLegacyUI } from 'store/Preferences/selectors';

const Preview = React.forwardRef((props, ref) => {
  const workspaces = useSelector(selectWorkspaces);
  const type = useSelector(selectType);
  const data = useSelector(selectData);
  const graph = graphTemplates.find((g) => g.key === type);
  const showLegacyUI = useSelector(selectShowLegacyUI);

  const getData = (data) => {
    if (type !== MONTHLYEVOLUTIONBYSEVERITY && type !== MONTHLYEVOLUTIONBYSTATUS) return data.linked_data;

    return parseData(data.linked_data);
  };

  if (!type) return null;

  return (
    <Wrapper showNewUI={ !showLegacyUI }>
      <Header />
      { !isEmpty(data.linked_data) && workspaces.length > 0
        ? (
          <WrapperPreview ref={ ref }>
            <Graphs sideNoteType={ graph.sideNoteType } type={ type } data={ data.linked_data } />
            <Table name={ graph.name } type={ type } data={ getData(data) } />
          </WrapperPreview>
          )
        : <Empty /> }
    </Wrapper>
  );
});

Preview.displayName = 'Preview';

export default Preview;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import {
  Wrapper, Content, Description, LinkDescription,
  FeatureWrapper, FeatureImage, CloseIcon, TitleWrapper, TitleBold
} from './styled';
import BlueButton from 'Common/Components/BlueButton/index';
import Image from 'Images/ff_users.png';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_TOUR_FINISHED } from 'store/modals/modals';
import { useDispatch, useSelector } from 'react-redux';
import { selectType } from 'store/Faraday/selectors';

const EnjoyPopup = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeModal(MODAL_TOUR_FINISHED));
  const type = useSelector(selectType);
  const mailto = type === 'faraday-personal' ? 'mailto:helpdeskpersonal@infobytesec.zohodesk.com' : 'mailto:support@faradaysec.com';

  return (
    <ReduxModal id={ MODAL_TOUR_FINISHED }>
      <Wrapper>
        <CloseIcon onClick={ onClose } />
        <FeatureWrapper>
          <FeatureImage src={ Image } />
        </FeatureWrapper>
        <Content>
          <TitleWrapper>
            <FormattedMessage id="tour.enjoy.title" values={ { b: (chunks) => <TitleBold children={ chunks } /> } } />
          </TitleWrapper>
          <Description>
            <FormattedMessage
              id={ 'tour.enjoy.description' }
              values={ {
                doc: (chunks) => <LinkDescription href="https://docs.faradaysec.com/" target="_blank" children={ chunks } />,
                web: (chunks) => <LinkDescription href="https://faradaysec.com/" target="_blank" children={ chunks } />,
                mail: (chunks) => <LinkDescription href={ mailto } target="_blank" children={ chunks } />,
                br: <br />
              } }
            />
          </Description>
          <BlueButton label={ intl.formatMessage({ id: 'tour.button' }) } onClick={ onClose } />
        </Content>
      </Wrapper>
    </ReduxModal>
  );
};

export default EnjoyPopup;

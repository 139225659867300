/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InputFilter from 'Common/Components/InputFilter';
import {
  getTemplates,
  setValueFieldPreview,
  setField,
  addPolicy,
  removePolicy,
  selectCheckbox,
  addReference,
  removeReference,
  addCVE,
  removeCVE
} from 'Screens/ManageEditCreate/actions/Actions';
import {
  selectTemplates,
  selectManageEditCreate,
  selectReadonly,
  selectNameField
} from 'store/ManageEditCreate/selectors';
import SeverityDropdown from 'Common/Components/SeverityDropdown';
import EORDropdown from 'Common/Components/EORDropdown';
import CustomList from 'Common/Components/CustomList';
import Impacts from 'Common/Components/Impacts';
import MarkdownInput from '../MarkdownInput';
import {
  Wrapper, Row, ExternalId, NewColumn, Gap, Title
} from './styled';
import debounce from 'lodash/debounce';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import CVE from './InputCVE';

class General extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      policy: '',
      references: ''
    };

    this.applyTemplate = this.applyTemplate.bind(this);
    this.setPolicyViolations = this.setPolicyViolations.bind(this);
    this.setReferences = this.setReferences.bind(this);
    this.setExternalId = this.setExternalId.bind(this);
    this.saveDescription = this.saveDescription.bind(this);
    this.saveResolution = this.saveResolution.bind(this);
    this.debouncedSetField = debounce((fieldName, value) => this.props.setVulnField(fieldName, value), 150);
  }

  setPolicyViolations (value) {
    this.setState({ policy: value });
  }

  setReferences (value) {
    this.setState({ references: value });
  }

  setExternalId (value) {
    this.debouncedSetField('externalId', value);
  }

  applyTemplate (template) {
    const { setVulnField } = this.props;
    setVulnField('name', template.name);
    setVulnField('description', template.description);
    setVulnField('resolution', template.resolution);
    setVulnField('severity', template.exploitation);
    setVulnField('policies', template.policyviolations);
    setVulnField('references', template.refs);
    setVulnField('externalId', template.external_id);
    setVulnField('accountability', template.impact.accountability);
    setVulnField('availability', template.impact.availability);
    setVulnField('integrity', template.impact.integrity);
    setVulnField('confidentiality', template.impact.confidentiality);
    setVulnField('easeOfResolution', template.easeofresolution);
    setVulnField('data', template.data);
    setVulnField('customFields', template.customfields);
    setVulnField('CVE', template.cve);
    setVulnField('cvss2', template.cvss2);
    setVulnField('cvss3', template.cvss3);
  }

  saveDescription (value) {
    this.debouncedSetField('description', value);
  }

  saveResolution (value) {
    this.debouncedSetField('resolution', value);
  }

  render () {
    const {
      getAllTemplates,
      templates,
      manageEditCreate,
      addPolicyViolation,
      removePolicyViolation,
      addReference,
      removeReference,
      canUpdate,
      addCVE,
      removeCVE,
      name
    } = this.props;

    const impacts = {
      accountability: manageEditCreate.accountability,
      availability: manageEditCreate.availability,
      confidentiality: manageEditCreate.confidentiality,
      integrity: manageEditCreate.integrity
    };

    return (
      <Wrapper>
        <NewColumn>
        <InputFilter
          getObjects={ getAllTemplates }
          data={ templates }
          width="405px"
          height="38px"
          placeholder="Search or give a name to your Vulnerability"
          onSelect={ this.applyTemplate }
          onChange={ this.debouncedSetField }
          value={ name }
          required
        />
          <Gap />
          <Title>CVE</Title>
          <CVE
            listData={ manageEditCreate.CVE }
            onAdd= { addCVE }
            onRemove={ removeCVE }
          />
          <Gap />
                      <MarkdownInput
                        title="Description"
                        value={ manageEditCreate.description }
                        onBlur={ this.saveDescription }
                        isRequired
                        minHeight={ 130 }
                      />
            <ExternalId
              width="405px"
              height="38px"
              title="External ID"
              name="external_id"
              defaultValue={ manageEditCreate.externalId || '' }
              onChange={ this.setExternalId }
            />
          <Gap />

             <CustomList
               key="CL_POLICY_VIOLATIONS"
               title="Policy Violations"
               placeholder="Add a Policy Violation"
               addItem={ addPolicyViolation }
               removeItem={ removePolicyViolation }
               setItem={ this.setPolicyViolations }
               listData={ manageEditCreate.policies || [] }
               responsive
               canUpdate={ canUpdate }
             />
        </NewColumn>
        <NewColumn>
          <Row>
          <SeverityDropdown updateValue={ this.props.setVulnField } defaultValue={ manageEditCreate.severity || '' } />
          <EORDropdown updateValue={ this.props.setVulnField } defaultValue={ manageEditCreate.easeOfResolution || '' } />
          </Row>
          <Gap />

          <MarkdownInput
            title="Resolution"
            value={ manageEditCreate.resolution }
            onBlur={ this.saveResolution }
            minHeight={ 222 }
          />
            <Impacts selectCheckbox={ this.props.selectCheckbox } impacts={ impacts } />
            <Gap />
            <CustomList
              key="REFERENCES"
              title="References"
              placeholder="Add Reference"
              addItem={ addReference }
              removeItem={ removeReference }
              setItem={ this.setReferences }
              listData={ manageEditCreate.references || [] }
              responsive
              canUpdate={ canUpdate }
            />
        </NewColumn>
      </Wrapper>
    );
  }
}

General.propTypes = {
  templates: PropTypes.array.isRequired,
  manageEditCreate: PropTypes.object.isRequired,
  getAllTemplates: PropTypes.func.isRequired,
  setVulnField: PropTypes.func.isRequired,
  addPolicyViolation: PropTypes.func.isRequired,
  removePolicyViolation: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  getAllTemplates: () => {
    dispatch(getTemplates());
  },
  setValueFieldPreview: (vuln, field, value) => {
    dispatch(setValueFieldPreview(vuln, field, value));
  },
  setVulnField: (fieldName, value) => {
    dispatch(setField(fieldName, value));
  },
  addPolicyViolation: (val) => {
    dispatch(addPolicy(val));
  },
  removePolicyViolation: (index) => {
    dispatch(removePolicy(index));
  },
  addReference: (val) => {
    dispatch(addReference(val));
  },
  removeReference: (index) => {
    dispatch(removeReference(index));
  },
  addCVE: (val) => {
    dispatch(addCVE(val));
  },
  removeCVE: (val) => {
    dispatch(removeCVE(val));
  },
  selectCheckbox: (field) => {
    dispatch(selectCheckbox(field));
  }
});

const mapStateToProps = (state) => ({
  templates: selectTemplates(state),
  manageEditCreate: selectManageEditCreate(state),
  readonly: selectReadonly(state),
  name: selectNameField(state),
  canUpdate: selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.CREATE)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(General));


import styled from 'styled-components';
import colors from 'Styles/colors';
import { Button } from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/Tags/components/AddTagButton/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color:${colors.paleGrey};
  padding: 18px 12px 22px 20px;
  margin-top: 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  text-align: left;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
`;
Title.displayName = 'Title';

export const Subtitle = styled(Title)`
  margin: 10px 0;
  font-size: 12px;
  font-weight: normal;
  color: #63758d;
`;
Subtitle.displayName = 'Subtitle';

export const EditButton = styled(Button)`
  width: 68px;
`;
EditButton.displayName = 'EditButton';

export const Entities = styled.div`
  display: flex;
  margin-top: 15px;
  & > * {
    margin-right: 15px;
  }
`;
Entities.displayName = 'Entities';

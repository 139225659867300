/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import getColumns from './getColumns';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllNotification, selectNotificationsError, selectNotificationsLoading, selectSelectedNotifications } from 'store/Notifications/selectors';
import { getNotifications } from 'store/Notifications/actions';
import ActionBar from '../ActionBar';
import SelectAll from '../SelectAll';
import TableWrapper from './styled';
import { selectShowLegacyUI } from 'store/Preferences/selectors';

const Table = () => {
  const mockData = [...Array(13).keys()];
  const dispatch = useDispatch();
  const notifications = useSelector(selectAllNotification);
  const loading = useSelector(selectNotificationsLoading);
  const error = useSelector(selectNotificationsError);
  const selectedNotificationList = useSelector(selectSelectedNotifications);
  const showLegacyUI = useSelector(selectShowLegacyUI);
  const height = showLegacyUI ? 'calc(100vh - 270px)' : 'calc(100vh - 200px)';

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  if (error) return null;

  const getTrProps = (rowInfo) => {
    const read = rowInfo.original.read;
    const selected = selectedNotificationList.some(notification => notification.id === rowInfo.original.id);
    let background;

    if (selected) {
      background = '#edf2f7';
    } else if (read) {
      background = 'transparent';
    } else {
      background = 'rgba(237, 242, 247, 0.5)';
    }

    return {
      className: 'tableRow',
      style: {
        background
      }
    };
  };

  return (
    <>
      <ActionBar />
      <SelectAll />
      <TableWrapper>
        <CustomReactTable
          data={ loading ? mockData : notifications.sort((a, b) => new Date(b.event_date) - new Date(a.event_date)) }
          columns={ getColumns(loading) }
          minRows={ 0 }
          style={ { height } }
          manual
          showPagination={ false }
          getTrProps={ (_, rowInfo) => getTrProps(rowInfo) }
        />
      </TableWrapper>
    </>
  );
};

export default Table;

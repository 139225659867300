import StandardDropdown from 'Common/Components/StandarDropdown';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectField, selectJobModel, selectTaskTarget } from 'store/Jobs/selectors';
import {
  Wrapper, String, Label, Comment, Boolean
} from './styled';

const Value = ({
  field, value, onChange
}) => {
  const model = useSelector(selectJobModel);
  const target = useSelector(selectTaskTarget);
  const fieldModel = target || model;
  const fieldDetail = useSelector((state) => selectField(state, fieldModel, field));
  if (!fieldDetail) return null;

  const getFieldComponent = () => {
    if (get(fieldDetail, 'valid.length', 0) > 0) {
      return (
        <StandardDropdown
          width="200px"
          field="value"
          options={ fieldDetail.valid.map((value) => ({ desc: value, name: value, id: value })) }
          updateValue={ (f, item) => onChange(item.id) }
          defaultValue={ value }
          selectObject
        />
      );
    }
    if (fieldDetail.type === 'comment') return <Comment defaultValue={ value } onChange={ (e) => onChange(e.target.value) } />;
    if (fieldDetail.type === 'bool') return <Boolean defaultValue={ value } onClick={ (e) => { e.stopPropagation(); onChange(value === 'True' ? 'False' : 'True'); } }>{ !value || value === 'False' ? 'False' : 'True'}</Boolean>;
    if (fieldDetail.type === 'tag') {
      return (
      <>
      <String defaultValue={ value } onChange={ (e) => onChange(e.target.value) } />
      <Label>Separate tags with commas</Label>

      </>
      );
    }
    return <String defaultValue={ value } onChange={ (e) => onChange(e.target.value) } />;
  };
  return (
    <Wrapper key={ `${model}-${field}` }>
      <Label>Value</Label>
      { getFieldComponent() }
    </Wrapper>
  );
};

Value.defaultProps = {
  value: ''
};

Value.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default Value;

import React from 'react';
import get from 'lodash/get';
import Checkbox from 'Common/Components/Checkbox';
import capitalize from 'lodash/capitalize';
import { Label, CheckboxWrapper } from './styled';

const BooleanField = ({ param, parameters, onChangeField }) => {
  const required = get(parameters, `${param}.mandatory`, false);
  const extractName = (str) => capitalize(str.replace('_', ' '));

  return (
    <CheckboxWrapper key={ param } required={ required }>
      <Label>{extractName(param)}</Label>
      <Checkbox onChange={ (e) => onChangeField(param, !e.currentTarget.checked) } />
    </CheckboxWrapper>
  );
};
export default BooleanField;

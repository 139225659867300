import React, { useState, useRef } from 'react';
import { RiFileEditLine as EditLine, RiFileDownloadLine as DownloadLine } from 'react-icons/ri';
import ManualCreationModal from 'Screens/ManageEditCreate/components/ManualCreationModal';
import ImportFileCreationModal from 'Screens/ManageEditCreate/components/ImportFileCreationModal';
import useClickOutside from 'Hooks/useClickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { fetchGlobalHostsIsAllowed } from 'store/Host/selectors';
import { getTotalHosts } from 'store/Host/actions';
import {
  DropdownText, DropdownItem, Wrapper, DropdownWrapper, PlusIcon, Dropdown
} from './styled';

const CreateActions = () => {
  const [showModalCreation, setShowModalCreation] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.CREATE));
  const canGetGlobalHostsCount = useSelector(fetchGlobalHostsIsAllowed);
  const dispatch = useDispatch();

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const handleShow = () => {
    setShowModalCreation(true);
    setShowDropdown(false);
  };
  const handleClose = () => setShowModalCreation(false);
  const handleShowImportFromFile = () => {
    setShowModalImport(true);
    setShowDropdown(false);
  };
  const handleCloseImportFromFile = () => setShowModalImport(false);

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
    if (canGetGlobalHostsCount && !showDropdown) dispatch(getTotalHosts()); // calcula el total de hosts sin filtro.
  };

  return canCreate
    ? (
    <Wrapper ref={ dropdownRef }>
      <DropdownWrapper onClick={ handleDropdown } data-tour="Add Vulnerability">
        <PlusIcon />
        <DropdownText>Add Vulnerability</DropdownText>
      </DropdownWrapper>
      {showDropdown && (
      <Dropdown>
        <DropdownItem onClick={ handleShow } role="button" aria-label="Manual Creation" type="button">
          <EditLine size="1.3em" color="#5a6b7d" />
          Manual Creation
        </DropdownItem>
        <DropdownItem onClick={ handleShowImportFromFile } role="button" aria-label="Import from File" type="button">
          <DownloadLine size="1.3em" color="#5a6b7d" />
          Import from File
        </DropdownItem>
      </Dropdown>
      )}

      <ManualCreationModal show={ showModalCreation } handleClose={ handleClose } />
     { showModalImport && <ImportFileCreationModal handleClose={ handleCloseImportFromFile } /> }
    </Wrapper>
      )
    : null;
};

export default CreateActions;

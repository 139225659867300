import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import Layout from 'Screens/Dashboard/components/Layout';
import ImportFileCreationModal from 'Screens/ManageEditCreate/components/ImportFileCreationModal';
import { getTopHosts, getVulnsCount, getSummary } from 'Screens/Dashboard/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

import EmptyVulns from 'Common/Components/EmptyVulns';
import emptyFeed from 'Images/empty-feed.png';
import ManualCreationModal from 'Screens/ManageEditCreate/components/ManualCreationModal';

import { StyledCol, Wrapper } from './Dashboard/styled';
import { selectIsFetching, selectReloadVulnerabilities, selectTools } from 'store/Dashboard/selectors';
import { CLOSED_FILTERS, CONFIRMED_FLAG_FILTERS } from 'store/Filters/constants';
import { isFilteringBy, selectStoredDashboardFilter } from 'store/Filters/selectors';

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const tools = useSelector(selectTools);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const reloadVulnerabilities = useSelector(selectReloadVulnerabilities);
  const totalVulns = useSelector((state) => get(state, 'dashboard.tools[3].data.stats.total_vulns', 0));
  const isFetching = useSelector(selectIsFetching);
  const storeDashboardFilters = useSelector(selectStoredDashboardFilter);
  const isFiltered = storeDashboardFilters.closed || storeDashboardFilters.confirmed;

  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;

  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));

  useEffect(() => {
    if (!isFilteringByConfirm && !isFilteringByClosed) {
      dispatch(getSummary());
    }
  }, [dispatch, workspaceSelected, isFilteringByConfirm, isFilteringByClosed]);

  useEffect(() => {
    dispatch(getVulnsCount());
    dispatch(getTopHosts());
  }, [workspaceSelected, reloadVulnerabilities, dispatch, getVulnsCount, getTopHosts]);

  const [showModalUploadVulns, toggleModalUploadVulns] = useState(false);
  const [showModalCreateVuln, toggleModalCreateVuln] = useState(false);

  if ((totalVulns === 0 && !isFiltered) && !isFetching) {
    return (
      <>
        <EmptyVulns image={ emptyFeed } toggleModalUploadVulns={ () => toggleModalUploadVulns(true) } toggleModalCreateVuln={ () => toggleModalCreateVuln(true) } />
        { showModalUploadVulns && <ImportFileCreationModal handleClose={ () => toggleModalUploadVulns(false) } /> }
        <ManualCreationModal show={ showModalCreateVuln } handleClose={ () => toggleModalCreateVuln(false) } />
      </>
    );
  }

  return (
    <Wrapper>
      <StyledCol>
        { tools.filter((t) => t.render).length > 0 ? <Layout tools={ tools } /> : null }
      </StyledCol>
    </Wrapper>
  );
};

export default DashboardLayout;

import React, { useCallback, useState } from 'react';
import 'Common/styles/commonStyles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setWorkspaceFilter } from 'Screens/Workspaces/actions/Actions';
import { setFilterTemplates } from 'Screens/KnowledgeBase/actions/Actions';
import { selectLocation } from 'store/Router/selectors';
import {
  Wrapper, Input, SearchInput,
  SearchIcon, ClearIcon, SearchButton
} from './styled';
import get from 'lodash/get';
import { setFilterUsers } from 'Screens/Users/actions/Actions';

const BasicSearcher = () => {
  const [filterText, setFilterText] = useState('');
  const dispatch = useDispatch();
  const pathname = get(useSelector(selectLocation), 'pathname', '');

  const onChangeHandler = (text) => {
    if (text === '') clearFilter();
    else setFilterText(text);
  };

  const getPlaceholder = () => {
    if (pathname.includes('/workspaces')) return 'Search Workspaces';
    if (pathname.includes('/knowledge_base')) return 'Search Templates';
    if (pathname.includes('/users')) return 'Search Users';
    return 'Search';
  };

  const clearFilter = useCallback(() => {
    setFilterText('');
    if (pathname.includes('/workspaces')) dispatch(setWorkspaceFilter(''));
    if (pathname.includes('/knowledge_base')) dispatch(setFilterTemplates(''));
    if (pathname.includes('/users')) dispatch(setFilterUsers(''));
  }, [dispatch, pathname]);

  const searchFunction = () => {
    const trimmedValue = filterText.trim();
    if (pathname.includes('/workspaces')) dispatch(setWorkspaceFilter(trimmedValue));
    if (pathname.includes('/knowledge_base')) dispatch(setFilterTemplates(trimmedValue));
    if (pathname.includes('/users')) dispatch(setFilterUsers(trimmedValue));
  };

  const onEnterSearch = (e) => e.key === 'Enter' && searchFunction();

  return (
    <Wrapper>
      <SearchInput>
        <Input placeholder={ getPlaceholder() } value={ filterText } onChange={ (e) => onChangeHandler(e.target.value) } onKeyDown={ onEnterSearch } />
      </SearchInput>
      <ClearIcon onClick={ clearFilter } />
      <SearchButton onClick={ searchFunction } label={ <SearchIcon /> } />
    </Wrapper>
  );
};

export default BasicSearcher;

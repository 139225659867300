import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import some from 'lodash/some';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import IconButton from 'Common/Components/IconButton';
import Trash from './styled';
import { WithPermission } from 'Common/Components/WithPermission';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import DeleteConfirmationModal from '../../DeleteConfirmationModal';
import ModalWarning from 'Common/Components/ModalWarning';

// eslint-disable-next-line import/prefer-default-export
export const WsDeleteButton = () => {
  const selectedWs = useSelector(selectWorkspacesSelected) || [];
  const currentWs = useSelector(selectCurrentWorkspace) || {};

  // Define un único estado para el tipo de modal.
  const [modalType, setModalType] = useState(null); // 'warning', 'confirmation' o null

  const onClick = () => {
    if (some(selectedWs, ws => ws.name === currentWs)) {
      setModalType('warning');
    } else {
      setModalType('confirmation');
    }
  };

  const closeModal = () => {
    setModalType(null);
  };

  return (
    <WithPermission permission={ FEATURE_WORKSPACES } type={ PERMISSION_TYPES.DELETE }>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ onClick } />
      {modalType === 'confirmation' && <DeleteConfirmationModal onCloseCallback={ closeModal } />}
      {modalType === 'warning' && (
        <ModalWarning
          onCloseCallback={ closeModal }
          title='Delete Workspace'
          description='The Workspace you´re currently using can´t be deleted. Switch to a different Workspace and retry.'
        />
      )}
    </WithPermission>

  );
};

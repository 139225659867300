import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { updateHost } from 'store/Host/actions';
import { Wrapper, HostIp, Edit } from './styled';
import Importance from 'Common/Components/Importance';
import { selectHostDetail } from 'store/HostDetail/selectors';
import { showEditModal } from 'store/HostEditCreate/actions';

const Ip = () => {
  const dispatch = useDispatch();
  const host = useSelector(selectHostDetail);
  const canEdit = useSelector((state) => selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.UPDATE));
  const [importance, setImportance] = useState();

  useEffect(() => {
    if (host && host.importance) setImportance(host.importance);
  }, [host]);

  const callback = (importance) => {
    setImportance(importance);
    dispatch(updateHost(host._id, { importance }));
  };

  return (
    <Wrapper>
      <Importance importance={ importance } callback={ callback } />
      <HostIp>{host.ip}</HostIp>
      {canEdit && <Edit onClick={ () => dispatch(showEditModal()) } />}
    </Wrapper>
  );
};

export default Ip;

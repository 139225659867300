import React, { useEffect } from 'react';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { CheckboxLabel, WrapperCheckbox } from 'Screens/Contextualization/Settings/common-styled';
import { loadLoginSettings } from 'store/Settings/loginSettingsActions';
import { Wrapper, Title } from './styled';

const LoginSettings = ({ checked, setData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLoginSettings());
  }, [dispatch]);

  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: 'preferences.account.loginSettings.title' }) }</Title>
      <WrapperCheckbox>
        <CustomCheckbox id="logMeLastWorkspaceUsed" label="" handleCheckboxChange={ () => setData(!checked) } isChecked={ checked } />
        <CheckboxLabel>{intl.formatMessage({ id: 'preferences.account.loginSettings.allwaysLogMe.title' }) }</CheckboxLabel>
      </WrapperCheckbox>
    </Wrapper>
  );
};
export default LoginSettings;

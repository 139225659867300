import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import IconButton from 'Common/Components/IconButton';
import { Edit } from '../../styled';
import { redirectToVulnDetail } from 'store/Manage/actions';

const EditButton = () => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector((state) => get(state, 'manage.vulnsSelected', []));
  const handleClick = () => dispatch(redirectToVulnDetail(vulnsSelected[0]._id));
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));

  if (!canUpdate) return null;
  return (
    <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } />
  );
};

export default EditButton;

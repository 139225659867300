import React from 'react';
import SearchBar from 'Common/Components/SearchBar/index';
import 'Common/styles/commonStyles.scss';
import { useSelector } from 'react-redux';
import { selectTotalVulns, selectWorkspaces } from 'store/Workspace/selectors';
import { selectPathname } from 'store/Router/selectors';
import BasicSearcher from './BasicSearcher';
import { selectVulnsCount } from 'store/Manage/selectors';

const Searcher = () => {
  const pathname = useSelector(selectPathname);
  const totalVulns = useSelector(selectTotalVulns);
  const vulnsCountManage = useSelector(selectVulnsCount);
  const workspaces = useSelector(selectWorkspaces);

  const canShowNewSearcher = (pathname.includes('/manage') && (totalVulns > 0 || vulnsCountManage > 0)) || (pathname.includes('/host')) || (pathname.includes('/knowledge_base'));
  const canShowOldSearcher = pathname.includes('/workspaces') && workspaces.length > 0;

  if (canShowNewSearcher) return <SearchBar />;
  if (canShowOldSearcher) return <BasicSearcher />;

  return null;
};

export default Searcher;

import React, { useState, useEffect } from 'react';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import { withBreakpoints } from 'react-breakpoints';
import {
  selectVulnsSelected,
  selectVulnsList,
  selectIsFetching,
  selectIsFetchingExpandedData,
  selectShowDetail,
  selectVulnsCount
} from 'store/Manage/selectors';
import { setOrderByVulns, expandGroupByVulns } from 'store/Manage/filterActions';
import { selectGroupByField, selectFilters, selectIsFiltering } from 'store/Filters/selectors';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';
import { selectManageFields } from 'store/Preferences/manage/selectors';
import {
  showContextMenu,
  selectRow,
  unSelectAll
} from 'store/Manage/actions';
import { setFieldWidth } from 'store/Preferences/manage/actions';
import { PropTypes } from 'prop-types';
import { selectTourFinished } from 'store/Preferences/Tour/selectors';
import getColumns from './columns/Columns';
import { handleResizedChange, getThProps, getTrProps } from './Functions';
import SubTable from './components/SubTable';
import EmptyState from './components/EmptyState';
import { selectRiskScoreFeatureFlag } from 'store/Faraday/selectors';
import { selectVulnsCountForManage } from 'store/Workspace/selectors';

const Table = ({ currentBreakpoint }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState([]);

  const vulnsCountWs = useSelector(selectVulnsCountForManage);
  const vulnsCount = useSelector(selectVulnsCount);
  const tourFinished = useSelector(selectTourFinished);
  const groupByField = useSelector((state) => selectGroupByField('vulns', state));
  const customFields = useSelector(selectCustomAttributes);
  const showVulnDetail = useSelector(selectShowDetail);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isFetching = useSelector(selectIsFetching);
  const isFetchingExpandedData = useSelector(selectIsFetchingExpandedData);
  const data = useSelector(selectVulnsList);
  const fields = useSelector(selectManageFields);
  const filters = useSelector((state) => selectFilters('vulns', state));
  const isFiltering = useSelector((state) => selectIsFiltering('vulns', state));
  const isAllowedByLicense = useSelector(selectRiskScoreFeatureFlag);

  const noDataText = isFetching ? '' : <FormattedMessage id="workspaces.table.noData" />;
  const columns = getColumns(true, customFields, showVulnDetail, currentBreakpoint, groupByField, fields, isFetching, isFetchingExpandedData, null, isAllowedByLicense);
  const subTableColumns = (hasGroupedData) => getColumns(false, customFields, showVulnDetail, currentBreakpoint, groupByField, fields, isFetching, isFetchingExpandedData, hasGroupedData);

  const selectRowAction = (row) => dispatch(selectRow({}, row));
  const unSelectAllAction = (row) => dispatch(unSelectAll({}, row));
  const setFieldWidthAction = (field, value) => dispatch(setFieldWidth(field, value, 'manage_table'));
  const showContextMenuAction = (value, xPos, yPos) => dispatch(showContextMenu(value, xPos, yPos));

  const onExpandedChange = (expanded, index) => {
    if (expanded[index]) {
      const expandData = data[index];
      dispatch(expandGroupByVulns(index, expandData));
    }
    setExpanded(expanded);
  };

  const handleResizeColumn = (newResized) => !groupByField && handleResizedChange(newResized, fields, setFieldWidthAction);

  const fetchData = (tableState) => !isFetching && dispatch(setOrderByVulns(tableState.sorted));

  useEffect(() => {
    setExpanded([]);
  }, [groupByField, filters]);

  const mockData = [...Array(15).keys()];

  if (!groupByField && tourFinished && vulnsCount === 0 && vulnsCountWs === 0 && !isFetching && !isFiltering) return <EmptyState />;
  return (
    <CustomReactTable
      data={ (!isFetching || groupByField) ? data : mockData }
      columns={ columns }
      minRows={ 0 }
      manual
      onFetchData={ fetchData }
      showPagination={ false }
      getTrProps={ (_, rowInfo) => getTrProps(rowInfo, vulnsSelected, selectRowAction, unSelectAllAction, showContextMenuAction, isFetching) }
      getTheadThProps={ (_, __, column) => getThProps(column, fields, currentBreakpoint, showVulnDetail, showContextMenuAction, showContextMenu) }
      onResizedChange={ debounce(handleResizeColumn, 200) }
      expanded={ expanded }
      onExpandedChange={ onExpandedChange }
      noDataText={ noDataText }
      SubComponent={ (row) => <SubTable row={ row } getColumns={ subTableColumns } /> }
    />
  );
};

Table.propTypes = {
  currentBreakpoint: PropTypes.string.isRequired
};

export default withBreakpoints(Table);

import React from 'react';
import { Wrapper, Label, LightLabel } from './styled';

const EmptyState = () => {
  return (
    <Wrapper>
      <Label>You currently have 0 Workspaces</Label>
      <LightLabel>Please contact your admin.</LightLabel>
    </Wrapper>
  );
};

export default EmptyState;

import React, { useEffect } from 'react';
import { Col, Row } from 'Common/Components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestComments } from 'Screens/Dashboard/actions/Actions';
import { selectIsFetching, selectLatestComments } from 'store/Dashboard/selectors';
import getCommentCreator from 'Screens/Dashboard/components/ToolbarItems/LatestComments/functions';
import DateFormatter from 'Common/Components/DateFormatter';
import ColorImage from 'Common/Components/ColorImage';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter } from 'store/Filters/actions';
import { showVulnerabilityDetail } from 'store/Manage/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import {
  Comments, Comment, Text, Time, Title, Texts, Empty
} from './styled';
import SpinnerComponent from 'Common/Components/SpinnerContainer';

function isSystemComment (comment) {
  return comment.comment_type === 'system';
}

const LatestComments = () => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const data = useSelector(selectLatestComments);
  const isLoadingComments = useSelector(selectIsFetching);

  useEffect(() => {
    dispatch(getLatestComments());
  }, [dispatch, workspaceSelected]);

  const onClick = async (id, type) => {
    if (type === 'vulnerability') {
      dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnerabilityById(id)));
      dispatch(showVulnerabilityDetail(id, 'comment'));
    } else if (type === 'host') {
      dispatch(setFilter('assets', DASHBOARD_FILTERS.assetsById(id)));
      dispatch(redirect(`/host/${workspaceSelected}`));
    } else if (id) {
      dispatch(showVulnerabilityDetail(id, 'comment'));
    }
  };

  if (isLoadingComments) return <SpinnerComponent height={ '105px' } />;

  let content = <Empty>No comments has been found yet.</Empty>;
  if (data && data.length > 0) {
    content = (
      <Comments>
        {data.slice(0, 5).map((comment) => (
          <Comment key={ comment.id } onClick={ () => onClick(comment.object_id, comment.object_type) }>
            <ColorImage title={ getCommentCreator(comment) } role={ comment.comment_type } />
            <Texts>
              <Text isSysComment={ isSystemComment(comment) }>{comment.text}</Text>
              <Time><DateFormatter date={ comment.create_date } /></Time>
            </Texts>
          </Comment>
        ))}
      </Comments>
    );
  }

  return (
    <Col>
      <Row>
        <Title>Comments</Title>
      </Row>
      { content }
    </Col>
  );
};

export default LatestComments;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import IconButton from 'Common/Components/IconButton';
import { Trash } from '../../styled';
import { showVulnModalDelete } from 'store/Manage/actions';

const DeleteButton = () => {
  const canDelete = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.DELETE));
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showVulnModalDelete());

  if (!canDelete) return null;
  return (
    <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } />
  );
};

export default DeleteButton;

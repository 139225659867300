import React from 'react';
import { selectCurrentWorkspace, selectNotificationsEnabled } from 'store/Faraday/selectors';
import { selectIsLoggedIn } from 'store/Sesion/selectors';
import { selectLocation } from 'store/Router/selectors';
import { useSelector } from 'react-redux';
import Searcher from 'Common/Components/Searcher';
import WorkspaceSelector from './WorkspaceMenuSelector';
import Notifications from './Notifications';
import User from './User/userContainer';
import ProcessingQueueButton from './ProcessingQueueButton';
import { Header, Wrapper } from './styled';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

const NavMenu = () => {
  const loggedIn = useSelector((state) => selectIsLoggedIn(state));
  const location = useSelector((state) => selectLocation(state));
  const showWorkspaceSelector = location.pathname !== '/api-definitions';
  const currentWorkspace = useSelector((state) => selectCurrentWorkspace(state));
  const notificationsEnabled = useSelector((state) => selectNotificationsEnabled(state));
  const showNavigation = !!currentWorkspace;
  const showProcessingQueueButton = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.CREATE));

  return loggedIn && (
    <Header>
      { showWorkspaceSelector && <WorkspaceSelector /> }
      <Searcher />
      <Wrapper>
        { showProcessingQueueButton && <ProcessingQueueButton /> }
        {showNavigation && notificationsEnabled && <Notifications />}
        <User />
      </Wrapper>
    </Header>
  );
};

export default NavMenu;

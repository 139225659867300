
import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { Trash } from 'Screens/Manage/components/CustomActionBar/styled';
import { showVulnTemplateModalDelete } from 'Screens/KnowledgeBase/actions/Actions';

const DeleteButton = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showVulnTemplateModalDelete());

  return (
    <WithPermission permission={ FEATURE_VULN_TEMPLATE } type={ PERMISSION_TYPES.DELETE }>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } />
    </WithPermission>
  );
};

export default DeleteButton;

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import SendToTool from 'Common/Components/SendToTool';
import { Send } from './styled';
import { selectShowDetail, selectVulnsSelected } from 'store/Manage/selectors';
import {
  PERMISSION_TYPES, FEATURE_TICKETING_GITLAB, FEATURE_TICKETING_JIRA, FEATURE_TICKETING_SERVICENOW, FEATURE_TICKETING_WHD
} from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';

const SendToToolsButton = () => {
  const jiraAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const gitlabAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const serviceNowAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const whdAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));
  const vulnsSelected = useSelector(selectVulnsSelected);
  const showVulnDetail = useSelector(selectShowDetail);
  const isAllowedByRole = jiraAllowedByRole || gitlabAllowedByRole || serviceNowAllowedByRole || whdAllowedByRole;

  if (!isAllowedByRole) return null;
  return (
    <ContextMenuButton
      menu={ <SendToTool trigger="send_menu_manage" /> }
      trigger="send_menu_manage"
      display={ !isEmpty(vulnsSelected) && !showVulnDetail }
      icon={ <IconButton icon={ <Send /> } title="Send to Tools" onClick={ () => {} } /> }
    />
  );
};

export default SendToToolsButton;

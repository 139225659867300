import React from 'react';
import IconButton from 'Common/Components/IconButton';
import { showContextMenu } from 'store/Manage/actions';
import { selectShowContextMenu } from 'store/Manage/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Wrapper, MoreIcon } from './styled';

const OpenContextMenuButton = ({ display }) => {
  const showingContextMenu = useSelector(selectShowContextMenu);
  const dispatch = useDispatch();
  const toggleContextMenu = (e) => dispatch(showContextMenu(!showingContextMenu, e.pageX, e.pageY));

  if (display) {
    return (
      <Wrapper onClick={ toggleContextMenu }>
        <IconButton icon={ <MoreIcon /> } title="More" onClick={ () => {} } />
      </Wrapper>
    );
  }
  return null;
};

OpenContextMenuButton.propTypes = {
  display: PropTypes.bool.isRequired
};

export default OpenContextMenuButton;

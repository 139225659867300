import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectWorkspacesOrdered, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import some from 'lodash/some';
import WorkspaceItem from './components/WorkspaceItem';
import { Wrapper } from './styled';
import { getData } from 'Screens/Workspaces/actions/Actions';
import EmptyState from '../EmptyState';
import { selectIsAdmin } from 'store/Faraday/selectors';

const GridView = ({ setShowConfirmation, setShowWarning }) => {
  const workspaceList = useSelector(selectWorkspacesOrdered);
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  if (workspaceList.length === 0 && !isAdmin) return <EmptyState />;
  return (
    <Wrapper>
      { workspaceList.map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } setShowConfirmation={ setShowConfirmation } setShowWarning={ setShowWarning } />) }
    </Wrapper>
  );
};

export default GridView;

/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Table from './components/Table';
import { Wrapper, Text, Title, Subtitle, StyledButton, ButtonWrapper, IconWrapper, Config, Checkbox, NotifyWrapper } from './styled';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotificationCenter } from 'store/Notifications/selectors';
import api from 'services/api';
import { selectCurrentUserId } from 'store/Faraday/selectors';
import Settings from 'Screens/Settings';
import useModal from 'Hooks/useModal';
import { setNotificationSettings } from 'store/Notifications/actions';
import { selectShowLegacyUI } from 'store/Preferences/selectors';

const NotificationSettings = () => {
  const [ModalPreferences, showModalPreferences, toggleModalPreferences] = useModal(Settings);
  const showLegacyUI = useSelector(selectShowLegacyUI);
  return (
    <Wrapper showNewUI={ !showLegacyUI }>
      { showLegacyUI &&
        <>
          <Title>Notifications</Title>
          <Subtitle>Settings</Subtitle>
          <Text>Enable / disable event logging and trigger notifications / alerts</Text>
        </>
      }

      <Buttons
        toggleModalPreferences={ toggleModalPreferences }
      />
        <Table />
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showWorkspace } trackingClick={ ACTIONS.clickWorkspaces } />
      </ReduxModal>
      {showModalPreferences && <ModalPreferences onClose={ toggleModalPreferences } defaultTab="notifications" />}
    </Wrapper>
  );
};

export default NotificationSettings;

export const Buttons = ({ toggleModalPreferences }) => {
  const data = useSelector(selectNotificationCenter);
  const userId = useSelector(selectCurrentUserId);
  const [paused, setPaused] = useState(false);
  const [no_self_notify, setSelfNotify] = useState(false);
  const dispatch = useDispatch();

  // Whenever data is updated, update local state
  useEffect(() => {
    if (data) {
      setPaused(data.paused);
      setSelfNotify(data.no_self_notify);
    }
  }, [data]);

  const handlePauseAll = async () => {
    try {
      // Updating all notifications to be paused
      const updatedPausedState = !paused;
      const settings = await api.notifications.updateNotificationTypeApi(userId, 'paused', updatedPausedState);
      dispatch(setNotificationSettings(settings));
    } catch (error) {
      // console.error('Error updating pause all:', error);
    }
  };

  const handleSelfNotify = async () => {
    try {
      // Updating self notification settings
      const updatedSelfNotifyState = !no_self_notify;
      const settings = await api.notifications.updateNotificationTypeApi(userId, 'no_self_notify', updatedSelfNotifyState);

      dispatch(setNotificationSettings(settings));
    } catch (error) {
      // console.error('Error updating self notification settings:', error);
    }
  };

  return (
    <>
    <ButtonWrapper>
    <StyledButton type="white" onClick={ handlePauseAll }>
    {paused ? 'Resume All' : 'Pause All'}
    </StyledButton>
    <StyledButton type="grey" onClick={ toggleModalPreferences }>
        <IconWrapper>
          <Config />
        </IconWrapper>
        Configure Integrations
    </StyledButton>
    </ButtonWrapper>
      <NotifyWrapper>
      <Checkbox
        type="checkbox"
        checked={ !no_self_notify }
        onChange={ handleSelfNotify }
      />
      <Text bold>Notify me about my own activity</Text>
      </NotifyWrapper>
    </>
  );
};

/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import DRP from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import StringField from 'Common/Components/StringField';
import {
  CalendarWrapper, StringFieldContainer, Wrapper, CleanButton, CloseButton
} from './styled';

const moment = extendMoment(originalMoment);

class DateRangePicker extends React.Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      isOpen: false,
      value: null
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onSelect (value) {
    this.setState({ value });
    // if (value.end) this.onToggle();
  }

  onToggle () {
    const { isOpen, value } = this.state;

    if (isOpen && value) this.props.onClose(value.start, value.end);

    this.setState({ isOpen: !isOpen });
  }

  handleClickOutside (event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.state.isOpen) this.onToggle();
    }
  }

  render () {
    const today = moment();
    const {
      width, height, locale, start, end, id, marginNone
    } = this.props;

    const value = this.state.value
      // eslint-disable-next-line no-negated-condition
      ? (this.state.value.start.format('YYYY/MM/DD') !== this.state.value.end.format('YYYY/MM/DD') ? `${this.state.value.start.format('YYYY/MM/DD')} - ${this.state.value.end.format('YYYY/MM/DD')}` : `${this.state.value.start.format('YYYY/MM/DD')}`)
      : (start && end ? `${moment(start).format('YYYY/MM/DD')} - ${moment(end).format('YYYY/MM/DD')}` : (start ? `${moment(start).format('YYYY/MM/DD')}` : (end ? `${moment(end).format('YYYY/MM/DD')}` : `${today.clone().format('YYYY/MM/DD')}`)));
    return (
      <Wrapper ref={ this.wrapperRef }>
        <StringFieldContainer onClick={ this.onToggle } highlight={ (this.state.value && this.state.value.length > 0) || (start && end) }>
          <StringField width={ width } height={ height } placeholder={ value } onChange={ () => {} } readOnly id={ id } marginNone={ marginNone } />
        </StringFieldContainer>
        {this.state.isOpen && (
          <CalendarWrapper width={ 170 }>
            <DRP
              value={ this.state.value ? this.state.value : (start && end ? moment.range(moment(start).clone(), moment(end).clone()) : moment.range(today.clone(), today.clone())) }
              onSelect={ this.onSelect }
              singleDateRange={ false }
              locale={ locale }
              helpMessage=""
            />
            <CleanButton onClick={ () => { this.setState({ value: null }); this.props.cleanDates(); } }>Clean</CleanButton>
            <CloseButton disabled={ !this.state.value } onClick={ this.onToggle }>Save</CloseButton>
          </CalendarWrapper>
        )}
      </Wrapper>
    );
  }
}

export default DateRangePicker;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Account from 'Screens/Contextualization/Settings/components/Account';
import Report from 'Screens/Contextualization/Settings/components/Report';
import Smtp from 'Screens/Contextualization/Settings/components/Smtp';
import Saml from 'Screens/Contextualization/Settings/components/Saml';
import Ldap from 'Screens/Contextualization/Settings/components/Ldap';
import TicketingTools from 'Screens/Contextualization/Settings/components/TicketingTools';
import { ReactComponent as CustomAtributesIcon } from 'Images/customAttributes.svg';
import { ReactComponent as AccountIcon } from 'Images/preferences_icons_Account.svg';
import { ReactComponent as SMTPIcon } from 'Images/preferences_icons_SMTP.svg';
import { ReactComponent as ReportIcon } from 'Images/preferences_icons_Report.svg';
import { ReactComponent as TicketingIcon } from 'Images/preferences_icons_Ticketing.svg';
import { ReactComponent as AuthIcon } from 'Images/preferences_icons_Authentication.svg';
import {
  PERMISSION_TYPES,
  FEATURE_SETTINGS_REPORTING, FEATURE_SETTINGS_SMTP,
  FEATURE_SETTINGS_SAML, FEATURE_SETTINGS_LDAP, FEATURE_CUSTOM_FIELDS,
  FEATURE_USER_TOKENS
} from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import {
  Wrapper,
  Tabs,
  TabGroup,
  Title,
  Subtitle,
  Item,
  TabContent,
  StyledNotificationBell
} from './styled';
import 'Screens/Contextualization/Settings/styles.scss';
import CustomAttributes from '../CustomAttributes';
import { selectIsAdmin } from 'store/Faraday/selectors';
import NotificationsPreferences from 'Screens/Contextualization/Settings/components/Notifications';
import AccessTokens from '../AccessTokens';

const Menu = ({ onClose, defaultTab = 'account' }) => {
  const defaultVisibleComponent = defaultTab === 'notifications' ? <NotificationsPreferences onClose={ onClose } /> : <Account onClose={ onClose } />;
  const intl = useIntl();
  const [visibleComponent, setVisibleComponent] = useState(defaultVisibleComponent);
  const [active, setActive] = useState(defaultTab);
  const isAccountEnabled = true;

  const isAdmin = useSelector(selectIsAdmin);

  const isReportsEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_REPORTING, PERMISSION_TYPES.PATCH));
  const isSamlEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_SAML, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_SETTINGS_SAML, PERMISSION_TYPES.PATCH));
  const isSmtpEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_SMTP, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_SETTINGS_SMTP, PERMISSION_TYPES.PATCH));
  const isLdapEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_SETTINGS_LDAP, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_SETTINGS_LDAP, PERMISSION_TYPES.PATCH));
  const isCustomAtributespEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_CUSTOM_FIELDS, PERMISSION_TYPES.PATCH) && selectAllowedByFeature(state, FEATURE_CUSTOM_FIELDS, PERMISSION_TYPES.PATCH));
  const isUserTokensEnabled = useSelector((state) => selectAllowedByRole(state, FEATURE_USER_TOKENS, PERMISSION_TYPES.READ) && selectAllowedByFeature(state, FEATURE_USER_TOKENS, PERMISSION_TYPES.READ));

  const click = (key, component) => { setActive(key); setVisibleComponent(component); };
  const menuItems = [
    {
      title: intl.formatMessage({ id: 'preferences.general.title' }),
      canView: isAccountEnabled || isReportsEnabled,
      items: [
        {
          key: 'account', title: intl.formatMessage({ id: 'preferences.account.title' }), click: () => { click('account', <Account onClose={ onClose } />); }, icon: <AccountIcon />, canView: true
        },
        {
          key: 'report', title: 'Report', click: () => { click('report', <Report onClose={ onClose } />); }, icon: <ReportIcon />, canView: isReportsEnabled
        },
        {
          key: 'customAttributes', title: intl.formatMessage({ id: 'preferences.customAttributes.title' }), click: () => { click('customAttributes', <CustomAttributes onClose={ onClose } />); }, icon: <CustomAtributesIcon />, canView: isCustomAtributespEnabled
        }
      ]
    },
    {
      title: intl.formatMessage({ id: 'preferences.authentication.title' }),
      canView: isSamlEnabled || isLdapEnabled || isUserTokensEnabled,
      items: [
        {
          key: 'ldap', title: intl.formatMessage({ id: 'preferences.ldap.title' }), click: () => { click('ldap', <Ldap onClose={ onClose } />); }, icon: <AuthIcon />, canView: isLdapEnabled
        },
        {
          key: 'saml', title: intl.formatMessage({ id: 'preferences.saml.title' }), click: () => { click('saml', <Saml onClose={ onClose } />); }, icon: <AuthIcon onClose={ onClose } />, canView: isSamlEnabled
        },
        {
          key: 'accessTokens', title: intl.formatMessage({ id: 'preferences.accessTokens.title' }), click: () => { click('accessTokens', <AccessTokens onClose={ onClose } />); }, icon: <AuthIcon onClose={ onClose } />, canView: isUserTokensEnabled
        }
      ]
    },
    {
      title: intl.formatMessage({ id: 'preferences.integrations.title' }),
      canView: true,
      items: [
        {
          key: 'ticketing', title: intl.formatMessage({ id: 'preferences.ticketingTools.title' }), click: () => { click('ticketing', <TicketingTools onClose={ onClose } />); }, icon: <TicketingIcon />, canView: isAdmin
        },
        {
          key: 'notifications', title: intl.formatMessage({ id: 'preferences.notifications.title' }), click: () => { click('notifications', <NotificationsPreferences onClose={ onClose } />); }, icon: <StyledNotificationBell />, canView: true
        },
        {
          key: 'smtp', title: intl.formatMessage({ id: 'preferences.smtp.title' }), click: () => { click('smtp', <Smtp onClose={ onClose } />); }, icon: <SMTPIcon onClose={ onClose } />, canView: isSmtpEnabled
        }
      ]
    }

  ];

  return (
    <Wrapper>
      <Tabs>
        <Title>{intl.formatMessage({ id: 'preferences.title' })}</Title>
        { menuItems.filter((group) => group.canView).map((group) => (
          <TabGroup key={ group.title }>
            <Subtitle>{group.title}</Subtitle>
            { group.items.filter((item) => item.canView).map((item) => (
              <Item key={ item.key } active={ active === item.key } onClick={ item.click }>
                {item.icon}
                {' '}
                {item.title}
              </Item>
            ))}
          </TabGroup>
        ))}
      </Tabs>
      <TabContent>
        {visibleComponent}
      </TabContent>
    </Wrapper>
  );
};

Menu.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Menu;

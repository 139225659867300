import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetGraphSelected, viewDetail } from 'store/Analytics/actions';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import Preview from 'Screens/Analytics/components/CreateEdit/components/Preview';
import Modals from 'Screens/Analytics/components/Modals';
import useDownload from 'Hooks/useDownload';
import ActionBar from './components/ActionBar';
import Settings from './components/Settings';
import { Wrapper, WrapperContent } from './styled';

const EditGraph = () => {
  const dispatch = useDispatch();
  const [nodeRef, download] = useDownload();
  const showLegacyUI = useSelector(selectShowLegacyUI);

  const { id } = useParams();

  useEffect(() => {
    if (id > 0) {
      dispatch(viewDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => () => {
    dispatch(resetGraphSelected());
  }, [dispatch]);

  return (
    <>
      { showLegacyUI
        ? <Wrapper>
            <ActionBar download={ download } />
            <WrapperContent>
              <Settings />
              <Preview ref={ nodeRef } />
            </WrapperContent>
            <Modals />
          </Wrapper>
        : <Wrapper showNewUI={ !showLegacyUI }>
            <Settings />
            <WrapperContent showNewUI={ !showLegacyUI }>
              <ActionBar download={ download } />
              <Preview ref={ nodeRef } />
            </WrapperContent>
            <Modals />
          </Wrapper>
          }
    </>
  );
};

export default EditGraph;

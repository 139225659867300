import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';
import { MODAL_APPLY_TEMPLATE, MODAL_EVIDENCE_WARNING_UPLOAD } from 'store/modals/modals';
import selectModal from 'store/modals/selectors';
import ApplyTemplateModal from 'Screens/KnowledgeBase/components/EditVuln';
import { resetState } from 'store/Manage/actions';
import { TableContainer } from './styled';
import VulnsContextMenu from './components/ContextMenu';
import BulkUpdateConfirmationDialog from './components/BulkUpdateConfirmationDialog';
import Table from './components/Table';
import BulkUpdateModal from './components/BulkUpdateModal';
import CreateEditTagsModal from '../VulnerabilityDetail/components/CreateEditTagsModal';
import { selectVulnsTags } from 'store/Manage/selectors';
import { selectShowTagsModal } from 'store/Tags/selectors';
import SelectAll from './components/SelecAll';
import ModalWarning from 'Common/Components/EvidenceTab/components/ModalWarning';
import ReduxModal from 'Common/Components/ReduxModal';
import { selectError, selectErrorMessage } from 'store/ManageEditCreate/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { resetErrorValue } from 'Screens/ManageEditCreate/actions/Actions';

const ManageTable = () => {
  const dispatch = useDispatch();
  const showTemplateModal = useSelector((state) => selectModal(state, MODAL_APPLY_TEMPLATE));
  const vulnsTags = useSelector(selectVulnsTags);
  const showCreateTagModal = useSelector((state) => selectShowTagsModal('menu', state));
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  useEffect(() => {
    dispatch(getWorkspaces(false, true, true));
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  return (
    <TableContainer>
      <SelectAll />
      {showTemplateModal && <ApplyTemplateModal />}
      <VulnsContextMenu />
      <BulkUpdateModal />
      <BulkUpdateConfirmationDialog />
      { showCreateTagModal && <CreateEditTagsModal vulnsTags={ vulnsTags } entity="menu" /> }
      <Table />
      <ReduxModal id={ MODAL_EVIDENCE_WARNING_UPLOAD }>
        <ModalWarning />
      </ReduxModal>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetErrorValue()) } /> : null }
    </TableContainer>
  );
};

export default ManageTable;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRow, unselectTotalVulns } from 'store/Manage/actions';
import Checkbox from 'Common/Components/Checkbox';
import { selectShowQuickPlanner, selectVulnsSelected } from 'store/Manage/selectors';
import { ReactComponent as Drag } from 'Images/drag.svg';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import itemTypes from 'Common/Components/DragAndDrop/ItemTypes';

const CheckColum = ({ vuln }) => {
  const vulnsSelected = useSelector((state) => selectVulnsSelected(state));
  const dispatch = useDispatch();
  const isSelected = vulnsSelected.find((vulnSelected) => vulnSelected._id === vuln._id);
  const handleClick = (e) => {
    dispatch(unselectTotalVulns());
    dispatch(selectRow(e, vuln));
  };
  return (
    <div title="Select">
      <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
    </div>
  );
};

export const DraggableCheckColum = ({ vuln }) => { // eslint-disable-line import/prefer-default-export
  const isQuickPlannerOpen = useSelector(selectShowQuickPlanner);

  const [, drag, preview] = useDrag(() => ({
    type: itemTypes.VULN,
    item: vuln,
    collect: () => ({
      canDrag: isQuickPlannerOpen
    }),
    canDrag: isQuickPlannerOpen
  }), [vuln]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isQuickPlannerOpen) return <div ref={ drag }><Drag /></div>;
  return <CheckColum vuln={ vuln } />;
};

import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Header from './components/Header';
import Tabs from './components/Tabs';
import { Wrapper, DetailWrapper, ResizerWrapper, Resizer, ModalWrapper } from './styled';
import { useResizeX } from 'Hooks/useResize';
import useWindowDimensions from 'Hooks/useWindowSize';
import ImagePreviewModal from './components/ImagePreviewModal';
import CreateEditTagsModal from './components/CreateEditTagsModal';
import { selectVulnDetailTags } from 'store/Manage/selectors';
import { selectShowTagsModal } from 'store/Tags/selectors';
import IntegrationsModals from './components/IntegrationsModals';
import Warning from 'Common/Components/Warning';
import { useIntl } from 'react-intl';
import { resetWarning } from 'store/Settings/settingsActions';

const VulnerabilityDetail = () => {
  const [showTransparency, setShowTransparency] = useState(false);
  const ref = useRef(null);
  const intl = useIntl();
  const wrapperRef = useRef(null);
  const resizerRef = useRef(null);
  const { width } = useWindowDimensions();
  const minWidth = width < 3000 ? width * 0.55 : width * 0.35;
  const maxWidth = width < 3000 ? width * 0.75 : width * 0.55;
  const wrapperWidth = useResizeX(wrapperRef, resizerRef, minWidth, minWidth, maxWidth);
  const showCreateTagModal = useSelector((state) => selectShowTagsModal('vulnDetail', state));
  const vulnsTags = useSelector(selectVulnDetailTags);
  const error = useSelector((state) => get(state, 'settings.sendVulnToTool.payload.error', false));
  const message = useSelector((state) => get(state, 'settings.sendVulnToTool.payload.message', ''));
  const dispatch = useDispatch();

  const onScroll = () => {
    const scrollTop = get(ref, 'current.scrollTop', 0);
    if (scrollTop === 0) setShowTransparency(false);
    else setShowTransparency(true);
  };

  return (
    <>
      <ImagePreviewModal />
      { showCreateTagModal && <CreateEditTagsModal vulnsTags={ vulnsTags } entity="vulnDetail" /> }
      <IntegrationsModals />
       {error && message &&
      <ModalWrapper>
       <Warning
         title={ intl.formatMessage({ id: 'sendToTool.warnings.oops.title' }) }
         onClose={ () => dispatch(resetWarning()) }
       >
            {message}
       </Warning>
      </ModalWrapper>
          }
      <Wrapper ref={ wrapperRef } wrapperWidth={ wrapperWidth }>
        <ResizerWrapper ref={ resizerRef } children={ <Resizer /> } />
       <DetailWrapper>
          <Header showTransparency={ showTransparency } />
          <Tabs tabRef={ ref } onScroll={ onScroll } />
       </DetailWrapper>
      </Wrapper>
    </>
  );
};

export default VulnerabilityDetail;

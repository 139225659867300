/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectRedirect, selectShowGridView, selectShowModalAssignUsers } from 'store/Workspace/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { stopRedirect, getUsers, selectWorkspace } from 'Screens/Workspaces/actions/Actions';
import { setSelectedEntity, resetFilters } from 'store/Filters/actions';
import Table from './components/Table';
import WSActionBar from './components/WSActionBar';
import Wrapper from './styled';
import GridView from './components/GridView';
import ModalAssignUsers from './components/ModalAssignUsers';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import { selectAllowGetUsers } from 'store/Session/selectors';
import DeactivateConfirmationModal from './components/GridView/components/ DeactivateConfirmationModal';
import ModalWarning from 'Common/Components/ModalWarning';

const Workspace = () => {
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const redirect = useSelector(selectRedirect);
  const showGridView = useSelector(selectShowGridView);
  const showModalAssignUsers = useSelector(selectShowModalAssignUsers);
  const allowGetUsers = useSelector(selectAllowGetUsers);
  const [showWarning, setShowWarning] = useState();
  const [showConfirmation, setShowConfirmation] = useState({ show: false, ws: '' });

  useEffect(() => {
    if (allowGetUsers && currentWorkspace) dispatch(getUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowGetUsers, dispatch]);

  useEffect(() => () => {
    dispatch(resetFilters('workspaces'));
    dispatch(setSelectedEntity(''));
    dispatch(selectWorkspace({
      rowSelected: {},
      newRowsSelected: [],
      lastSelected: -1,
      lastIndexForRange: -1
    }));
  }, [dispatch]);

  if (redirect) {
    dispatch(stopRedirect());
    return <Redirect to={ `/manage/${currentWorkspace}` } />;
  }

  return (
    <Wrapper>
      <WSActionBar />
      { showGridView
        ? <GridView setShowConfirmation={ setShowConfirmation } setShowWarning={ setShowWarning } />
        : <Table setShowConfirmation={ setShowConfirmation } setShowWarning={ setShowWarning } />
      }
      { showConfirmation.show ? <DeactivateConfirmationModal onCloseCallback={ () => setShowConfirmation({ show: false, name: '' }) } show={ showConfirmation.show } ws={ showConfirmation.ws } /> : null}
      { showWarning && <ModalWarning onCloseCallback={ () => setShowWarning(false) } title={ 'Archive Workspace' } description={ 'The Workspace you´re currently using can´t be archived. Switch to a different Workspace and retry.' } /> }
      { showModalAssignUsers && <ModalAssignUsers /> }
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showWorkspace } trackingClick={ ACTIONS.clickWorkspaces } />
      </ReduxModal>
    </Wrapper>
  );
};

export default Workspace;

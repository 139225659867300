import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import NotFound from 'Screens/Errores/NotFound';
import { PERMISSION_TYPES, FEATURE_AGENTS, FEATURE_CLOUD_AGENTS } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { Wrapper, ButtonWrapper, TopWrapper } from './styled';
import ActionBar from './components/ActionBar';
import Grid from './components/Grid';
import { AgentsTour } from 'Common/Components/Tour';
import Table from './components/Table';
import Tabs from './components/Tabs';
import { getCloudAgents, getUserAgents, toggleAgentsTab } from 'store/Agents/actions';
import { SelectShowCloudAgents, selectCloudAgents, selectUserAgents } from 'store/Agents/selectors';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import { GridViewButton, ListViewButton } from './components/ActionBar/components/ViewButtons';
import LegacyActionBar from './components/LegacyActionBar';

const Agents = () => {
  const dispatch = useDispatch();
  const [showGridView, setShowGridView] = useState(false);
  const showCloudAgents = useSelector(SelectShowCloudAgents);
  const userAgents = useSelector(selectUserAgents);
  const cloudAgents = useSelector(selectCloudAgents);
  const isAllowedByFeature = useSelector((state) => selectAllowedByFeature(state, FEATURE_AGENTS, PERMISSION_TYPES.READ));
  const isAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_AGENTS, PERMISSION_TYPES.READ));
  const isAllowed = isAllowedByFeature && isAllowedByRole;

  const isCloudAllowedByFeature = useSelector((state) => selectAllowedByFeature(state, FEATURE_CLOUD_AGENTS, PERMISSION_TYPES.READ));
  const isCloudAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_CLOUD_AGENTS, PERMISSION_TYPES.READ));
  const isCloudAllowed = isCloudAllowedByFeature && isCloudAllowedByRole;

  const showLegacyUI = useSelector(selectShowLegacyUI);
  const showNewUI = !showLegacyUI;

  useEffect(() => {
    if (showCloudAgents) {
      dispatch(getCloudAgents());
    } else {
      dispatch(getUserAgents());
    }
  }, [dispatch, showCloudAgents]);

  const toggleView = () => {
    setShowGridView(!showGridView);
  };

  const toggleAgents = (bool) => dispatch(toggleAgentsTab(bool));
  if (!isAllowed) return <NotFound />;

  return (
    <>
    { showLegacyUI && <AgentsTour /> }
    <Wrapper>
      { showLegacyUI && <LegacyActionBar onToggleView={ toggleView } showGridView={ showGridView } showCloudAgents={ showCloudAgents } /> }
      { showNewUI
        ? <TopWrapper>
            <Tabs onToggleAgents={ toggleAgents } showCloudAgents={ showCloudAgents } />
            <ButtonWrapper>
            {showGridView
              ? (
              <ListViewButton showGridView={ showGridView } onToggleView={ toggleView } />
                )
              : (
              <GridViewButton showGridView={ showGridView } onToggleView={ toggleView } />
                )}
            </ButtonWrapper>
          </TopWrapper>
        : <Tabs onToggleAgents={ toggleAgents } />
      }
      { showNewUI && <ActionBar showCloudAgents={ showCloudAgents } /> }
      {
        showGridView
          ? <Grid agents={ showCloudAgents ? cloudAgents : userAgents } isCloudAllowed={ isCloudAllowed } />
          : <Table agents={ showCloudAgents ? cloudAgents : userAgents } isCloudAllowed={ isCloudAllowed } />
      }
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showAgents } trackingClick={ ACTIONS.clickAgents } />
      </ReduxModal>
    </Wrapper>
    </>
  );
};

export default Agents;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addTag, removeTag, setAssetsTags } from 'store/Host/actions';
import { selectTags, orderedTags } from 'store/Tags/selectors';
import TagsModal from 'Common/Components/TagsModal';
import { selectHostsSelected } from 'store/Host/selectors';

const CreateEditTagsModal = ({ hostsTags, entity }) => {
  const wsTags = useSelector(selectTags);
  const hostsSelected = useSelector(selectHostsSelected);
  const tags = useSelector((state) => orderedTags(state, hostsTags, hostsSelected));
  const dispatch = useDispatch();

  const onClickTag = (tag) => {
    if (tag.partialSelected) {
      dispatch(addTag(tag.name));
    } else {
      if (tag.addedToVuln) dispatch(removeTag(tag.name));
      else dispatch(addTag(tag.name));
    }
  };

  const addTags = (newTags) => {
    dispatch(setAssetsTags(newTags));
  };
  return (
    <TagsModal tags={ tags } onClickTag={ onClickTag } addTags={ addTags } allTags={ wsTags } entity={ entity } />
  );
};

export default CreateEditTagsModal;

import React from 'react';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

import { ReactComponent as Dashboard } from 'Images/activity-dashboard.svg';
import { ReactComponent as Assets } from 'Images/assets.svg';
import { ReactComponent as Automation } from 'Images/automation.svg';
import { ReactComponent as Vulnerabilities } from 'Images/vulnerabilities.svg';
import { ReactComponent as Planner } from 'Images/tasks_icon.svg';
import { ReactComponent as Reports } from 'Images/reports.svg';
import { ReactComponent as Analytics } from 'Images/analytics.svg';
import { ReactComponent as Users } from 'Images/icon_users.svg';
import { ReactComponent as NotificationBell } from 'Images/icon-notifications-sidebar.svg';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';

import * as p from 'Hooks/usePermission/permissions';

import FFAnalytics from 'Images/ff_analytics.png';
import FFPlanner from 'Images/ff_planner.png';
import FFReports from 'Images/ff_reporting.png';
import FFScheduler from 'Images/ff_scheduler.png';
import FFPipelines from 'Images/ff_pipelines.png';

export const selectIsOpen = (state) => get(state, 'sideBar.isOpen', false);
export const selectPagesData = (state) => {
  const ws = selectCurrentWorkspace(state);
  const isEnabledByRole = (feature) => selectAllowedByRole(state, feature, p.PERMISSION_TYPES.READ);
  const isEnabledByFeature = (feature) => selectAllowedByFeature(state, feature, p.PERMISSION_TYPES.READ);
  const allowedToCreateByRole = (feature) => selectAllowedByRole(state, feature, p.PERMISSION_TYPES.CREATE);

  return [
    {
      name: 'Activity Dashboard',
      icon: <Dashboard />,
      subMenu: [
        {
          name: 'Activity Dashboard',
          url: `/feed/${ws}`,
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Vulnerabilities',
      icon: <Vulnerabilities />,
      subMenu: [
        {
          name: 'Manage',
          url: `/manage/${ws}`,
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Vuln Templates (KB)',
          url: '/knowledge_base',
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Assets',
      icon: <Assets />,
      subMenu: [
        {
          name: 'Assets',
          url: `/host/${ws}`,
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Analytics',
      icon: <Analytics />,
      url: '/analytics',
      subMenu: [
        {
          name: 'Analytics',
          url: '/analytics',
          enabledByRole: isEnabledByRole(p.FEATURE_ANALYTICS),
          enabledByFeature: isEnabledByFeature(p.FEATURE_ANALYTICS),
          FF: {
            image: FFAnalytics,
            description: 'upgradeLicense.sidebar.analytics.description'
          }
        }
      ]
    },
    {
      name: 'Planner',
      icon: <Planner />,
      subMenu: [
        {
          name: 'Planner',
          url: '/planner',
          enabledByRole: isEnabledByRole(p.FEATURE_PROJECTS),
          enabledByFeature: isEnabledByFeature(p.FEATURE_PROJECTS),
          FF: {
            image: FFPlanner,
            description: 'upgradeLicense.sidebar.planner.description'
          }
        }
      ]
    },
    {
      name: 'Reports',
      icon: <Reports />,
      subMenu: [
        {
          name: 'Reports',
          url: '/report',
          enabledByRole: isEnabledByRole(p.FEATURE_REPORTING),
          enabledByFeature: isEnabledByFeature(p.FEATURE_REPORTING),
          FF: {
            image: FFReports,
            description: 'upgradeLicense.sidebar.reporting.description'
          }
        }
      ]
    },
    {
      name: 'Automation',
      icon: <Automation />,
      subMenu: [
        {
          name: 'Agents',
          url: '/automation/agents',
          enabledByRole: isEnabledByRole(p.FEATURE_AGENTS),
          enabledByFeature: isEnabledByFeature(p.FEATURE_AGENTS)
        },
        {
          name: 'Scheduler',
          url: '/automation/schedule',
          enabledByRole: isEnabledByRole(p.FEATURE_SCHEDULER),
          enabledByFeature: isEnabledByFeature(p.FEATURE_SCHEDULER),
          FF: {
            image: FFScheduler,
            description: 'upgradeLicense.sidebar.scheduler.description'
          }
        },
        {
          name: 'Pipelines',
          url: '/automation/pipelines',
          enabledByRole: isEnabledByRole(p.FEATURE_PIPELINES),
          enabledByFeature: isEnabledByFeature(p.FEATURE_PIPELINES),
          FF: {
            image: FFPipelines,
            description: 'upgradeLicense.sidebar.pipelines.description'
          }
        },
        {
          name: 'Jobs',
          url: '/automation/jobs',
          enabledByRole: isEnabledByRole(p.FEATURE_JOBS),
          enabledByFeature: isEnabledByFeature(p.FEATURE_JOBS),
          FF: {
            image: FFPipelines,
            description: 'upgradeLicense.sidebar.pipelines.description'
          }
        }
      ]
    },
    {
      name: 'Users',
      icon: <Users />,
      subMenu: [
        {
          name: 'Users',
          url: '/users',
          enabledByRole: allowedToCreateByRole(p.FEATURE_USERS),
          enabledByFeature: isEnabledByFeature(p.FEATURE_USERS)
        }
      ]
    },
    {
      name: 'Notifications',
      icon: <NotificationBell />,
      subMenu: [
        {
          name: 'Log',
          url: '/notifications-log',
          enabledByRole: true, // isEnabledByRole(p.FEATURE_NOTIFICATIONS),
          enabledByFeature: true // isEnabledByFeature(p.FEATURE_NOTIFICATIONS)
        },
        {
          name: 'Settings',
          url: '/notifications-settings',
          enabledByRole: true, // isEnabledByRole(p.FEATURE_NOTIFICATIONS),
          enabledByFeature: true // isEnabledByFeature(p.FEATURE_NOTIFICATIONS)
        }
      ]
    }
  ];
};

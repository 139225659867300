import React from 'react';
import { useSelector } from 'react-redux';
import Vulnerabilities from 'Screens/Dashboard/components/ToolbarItems/Vulnerabilities';
import TopServices from 'Screens/Dashboard/components/ToolbarItems/TopServices';
import TopHosts from 'Screens/Dashboard/components/ToolbarItems/TopHosts';
import LatestVulns from 'Screens/Dashboard/components/ToolbarItems/LatestVulns';
import LatestTags from 'Screens/Dashboard/components/ToolbarItems/LatestTags';
import LatestComments from 'Screens/Dashboard/components/ToolbarItems/LatestComments';
import StatusChart from 'Screens/Dashboard/components/ToolbarItems/StatusChart';
import { Box } from 'Screens/Dashboard/components/ToolbarItems/Common/Box/styled';
import { StyledRow, StyledCol, SummaryStyledCol } from './styled';
import Feeds from 'Screens/Dashboard/components/ToolbarItems/Feeds';
import RiskChart from 'Screens/Dashboard/components/ToolbarItems/RiskChart';
import { selectRiskScoreFeatureFlag } from 'store/Faraday/selectors';
import AverageRisk from 'Screens/Dashboard/components/ToolbarItems/AverageRisk';
import Threats from 'Screens/Dashboard/components/ToolbarItems/Threats';
import SpinnerComponent from 'Common/Components/SpinnerContainer';
import { selectIsFetching } from 'store/Dashboard/selectors';

const Layout = () => {
  const showRiskFeature = useSelector(selectRiskScoreFeatureFlag);
  const isLoadingVulns = useSelector(selectIsFetching);

  return (
    <StyledRow direction="row" marginBottom marginRight>

      <StyledCol width="100%" maxWidth="375px">
        <Feeds />
      </StyledCol>

      <StyledCol marginBottom width="45%">
        <SummaryStyledCol>
          <Vulnerabilities />
        </SummaryStyledCol>

        <StyledCol>
          <LatestVulns />
        </StyledCol>

          <StyledRow marginRight>
            <StyledCol width="calc(55% - 14px)">
              <Box>
              {isLoadingVulns ? <SpinnerComponent height={ '350px' } /> : <TopHosts />}
              </Box>
            </StyledCol>
            <StyledCol width="45%">
              <Box>
              {isLoadingVulns ? <SpinnerComponent height={ '350px' } /> : <TopServices />}
              </Box>
            </StyledCol>
          </StyledRow>
      </StyledCol>

      <StyledCol marginBottom width="30%">
        { showRiskFeature &&
        <>
          <StyledCol>
            <Box style={ { paddingBottom: '0' } }>
            {isLoadingVulns ? <SpinnerComponent height={ '280px' } /> : <RiskChart />}
            </Box>
          </StyledCol>
          <StyledCol>
            <Box>
            {isLoadingVulns ? <SpinnerComponent height={ '110px' } /> : <AverageRisk />}
            </Box>
          </StyledCol>
          <StyledCol>
            <Box>
            {isLoadingVulns ? <SpinnerComponent height={ '120px' } /> : <Threats />}
            </Box>
          </StyledCol>
        </>
        }

        <StyledCol>
          <Box>
            {isLoadingVulns ? <SpinnerComponent height={ '280px' } /> : <StatusChart />}
          </Box>
        </StyledCol>

        <StyledCol>
          <Box>
            <LatestComments />
          </Box>
        </StyledCol>

        <StyledCol>
          <Box>
            <LatestTags />
          </Box>
        </StyledCol>

      </StyledCol>

    </StyledRow>
  );
};

export default Layout;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import IconButton from 'Common/Components/IconButton';
import EditColumnContext from 'Common/Components/EditColumnContext';
import { selectFields } from 'store/Preferences/host/selectors';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/host/actions';
import { EditColumns } from 'Screens/Manage/components/CustomActionBar/styled';

const AddRemoveColumnsButton = () => {
  const [showContext, toggleContext] = useState(false);
  const dispatch = useDispatch();
  const fields = useSelector(selectFields);

  const addRemoveColumns = (
    <EditColumnContext
      trigger="action-button-column"
      fields={ fields }
      toggle={ () => toggleContext(!showContext) }
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      resetDefault={ () => dispatch(resetDefault()) }
    />
  );

  return (
    <ContextMenuButton
      opened={ showContext }
      menu={ addRemoveColumns }
      trigger="action-button-column"
      display
      icon={ <IconButton icon={ <EditColumns /> } title="More" onClick={ () => {} } /> }
    />
  );
};

export default AddRemoveColumnsButton;

import React from 'react';
import { useDispatch } from 'react-redux';
import CreateActions from 'Screens/Host/components/CreateActions';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { toggleModalEditCreate } from 'store/Host/actions';

const CreateButton = () => {
  const dispatch = useDispatch();
  return (
    <WithPermission permission={ FEATURE_HOSTS } type={ PERMISSION_TYPES.CREATE }>
      <CreateActions setVisibilityCreateModal={ (value) => dispatch(toggleModalEditCreate(value)) } />
    </WithPermission>
  );
};

export default CreateButton;

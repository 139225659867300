import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectManageEditCreate } from 'store/ManageEditCreate/selectors';
import PropTypes from 'prop-types';
import General from 'Screens/ManageEditCreate/components/ManualCreationModal/components/General';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import TechnicalDetails from './components/TechnicalDetails';
import CustomFields from './components/CustomFields';
import Evidence from './components/Evidence';
import Assets from './components/Assets';
import {
  Wrapper, ModalBody, Tab, Tabs
} from './styled';
import Header from './components/Header';
import { MODAL_EVIDENCE_WARNING_UPLOAD } from 'store/modals/modals';
import ModalWarning from 'Common/Components/EvidenceTab/components/ModalWarning';
import ReduxModal from 'Common/Components/ReduxModal';

const ManualCreationModal = ({ handleClose, show }) => {
  const [activeTab, setActiveTab] = useState('general');
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const readonly = useSelector(selectIsReadOnly);

  const RenderTab = {
    general: <General />,
    technicalDetails: <TechnicalDetails />,
    customFields: <CustomFields />,
    assets: <Assets />,
    evidence: <Evidence />
  };

  const { name, description, severity } = useSelector(selectManageEditCreate);
  const disable = name === '' || description === '' || severity === '';

  if (!show) return null;
  return (
    <ModalWrapper>
      <Wrapper readonly={ readonly || !canUpdate }>
        <Header handleClose={ handleClose } activeTab={ activeTab } setActiveTab={ setActiveTab } />
        <ModalBody>
          <Tabs>
            <Tab onClick={ () => setActiveTab('general') } active={ activeTab === 'general' }>General</Tab>
            <Tab onClick={ () => setActiveTab('technicalDetails') } active={ activeTab === 'technicalDetails' }>Technical Details</Tab>
            <Tab onClick={ () => setActiveTab('customFields') } active={ activeTab === 'customFields' }>Custom Attributes</Tab>
            <Tab onClick={ () => setActiveTab('evidence') } active={ activeTab === 'evidence' }>Evidence</Tab>
            <Tab onClick={ () => !disable && setActiveTab('assets') } active={ activeTab === 'assets' } disable={ disable }>Assets</Tab>
          </Tabs>
          {RenderTab[activeTab]}
        </ModalBody>
      </Wrapper>
      <ReduxModal id={ MODAL_EVIDENCE_WARNING_UPLOAD }>
        <ModalWarning />
      </ReduxModal>
    </ModalWrapper>
  );
};

ManualCreationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ManualCreationModal;

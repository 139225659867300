/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import FlagIcon from 'Common/Components/FlagIcon';
import DateFormatter from 'Common/Components/DateFormatter';
import Skeleton from 'Common/Components/Skeleton';
import {
  Severity, Tag, Status, DateRow
} from './styled';
import Name from '../Name';
import { Confirm } from 'Screens/Manage/components/ManageTable/components/Table/columns';

export default function getColumns (fields, isFetching) {
  const alias = 'host.detail.vulnerabilities.table.columns';
  const columns = [{
    Header: () => (
      <FormattedMessage id={ `${alias}.id` } />
    ),
    maxWidth: 40,
    id: 'id',
    show: fields.id,
    Cell: (rowInfo) => (
      isFetching ? <Skeleton /> : rowInfo.original.id
    )
  }, {
    Header: () => (
      <div />
    ),
    accessor: 'severity',
    maxWidth: 55,
    minWidth: 55,
    id: 'severity',
    resizable: true,
    show: fields.severity,
    Cell: (rowInfo) => {
      const parsedSeverity = () => {
        if (rowInfo.original.severity === 'info') return 'informational';
        if (rowInfo.original.severity === 'med') return 'medium';
        return rowInfo.original.severity;
      };

      const capitalizedSeverity = rowInfo.original.severity && (parsedSeverity()[0].toUpperCase() + parsedSeverity().substring(1));
      const severityLetter = rowInfo.original.severity ? rowInfo.original.severity.charAt(0) : '';

      return (
        isFetching
          ? <Skeleton />
          : (
            <Tooltip title={ `Severity: ${capitalizedSeverity}` }>
              <Severity level={ rowInfo.original.severity }>{ severityLetter }</Severity>
            </Tooltip>
            )
      );
    }
  }, {
    Header: () => (
      <FlagIcon theme="allVulns" />
    ),
    accessor: 'confirmed',
    maxWidth: 45,
    minWidth: 45,
    id: 'confirmed',
    resizable: true,
    show: fields.confirmed,
    Cell: (rowInfo) => (
      // isFetching ? <Skeleton /> : <FlagIcon theme={ rowInfo.original.confirmed ? 'confirmed' : 'notConfirmed' } />
      isFetching ? <Skeleton /> : <Confirm vuln={ rowInfo.original } onAsset />
    ),
    getProps: () => ({ 'column-type': 'confirmation' })
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.name` } />
    ),
    accessor: 'name',
    maxWidth: 350,
    id: 'name',
    show: fields.name,
    Cell: (rowInfo) => (
      isFetching ? <Skeleton /> : <Name vuln={ rowInfo.original } />
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.date` } />
    ),
    accessor: 'create_date',
    id: 'create_date',
    show: fields.date,
    Cell: (rowInfo) => {
      if (isFetching) return <Skeleton />;
      if (rowInfo.original.date) {
        return (
          <DateRow>
            <DateFormatter date={ rowInfo.original.date } />
          </DateRow>
        );
      }
      return '';
    },
    maxWidth: 200
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.status` } />
    ),
    accessor: 'status',
    id: 'status',
    show: fields.status,
    Cell: (rowInfo) => (
      isFetching ? <Skeleton /> : <Status value={ rowInfo.original.status }>{rowInfo.original.status}</Status>
    ),
    maxWidth: 100
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.tags` } />
    ),
    accessor: 'tags',
    resizable: true,
    show: fields.tags,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : rowInfo.original.tags && rowInfo.original.tags.slice(0, 3).map((tag) => (
        <Tag key={ `tag_${tag}` }>{tag}</Tag>
        ))
    ),
    maxWidth: 300,
    sortable: false
  }];

  return columns;
}
